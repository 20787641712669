import React, { Component } from 'react';
import { connect } from 'react-redux';

import strings from 'resources/locales/Translate';

import './inputFieldStyles.scss';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { NewRefreshIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';

export class InputField extends Component {
  state = {
    isInfoOpen: false,
  }
  
  onBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  onChange = event => {
    this.props.onChange(event.target.value, this.props.field);
  };

  render() {
    const {
      dataTest,
      label,
      isRequired,
      type,
      extraProps,
      placeholder,
      icon,
      infoMessage,
      showExtraInfo,
      disabled,
      value,
      error,
      maxLength,
      className,
      bigSizeInput,
      appendIcon,
      adjustIcon,
      newRefreshIcon
    } = this.props;

    return (
      <div className={`input-field ${className}`} data-test={dataTest}>
        <div className='label-container d-flex justify-content-between align-items-center'>
          <div className='d-flex position-relative'>
            <p>{label} {isRequired && label && <span className='mandatory-asterik'>*</span>}</p>
            {showExtraInfo && <p className='input-info cursor-pointer'>i</p>}
            {showExtraInfo && <div className='info-message position-absolute'>{strings[infoMessage]}</div>}
          </div>
        </div>
        {icon && !appendIcon && <Icon name={icon} width={21} height={21} fill='rgba(154, 155, 156, 1)' className='new-version-search-icon' />}
        <div className='d-flex'>
          <input
            type={type}
            value={value || ''}
            className={`form-control rounded-0 ${bigSizeInput !== undefined ?bigSizeInput: ''} position-relative ` + (!!error && 'input-error') + ` ${!appendIcon ? 'input-cursor-adjust' : ''}`}
            placeholder={placeholder}
            maxLength={maxLength}
            {...extraProps}
            onChange={this.onChange}
            onBlur={this.onBlur}
            disabled={disabled}
          />
          {
            (newRefreshIcon &&  value) &&
            <div className='d-flex justify-content-center align-items-center ml-2' style={{width:'70px', height: '40px', border:'1px solid #0088d0'}} title="Refresh via ibase">
              <NewRefreshIcon />
            </div>
          }
        </div>
        {icon && appendIcon && <Icon name={icon} width={24} height={24} fill='#9a9b9c' className={`${adjustIcon ? 'new-search-icon-adjust' : 'search-icon'}`} />}
        {!!error && <span className='text-danger error-block'>{strings[error] || error}</span>}
      </div>
    );
  }
}

InputField.defaultProps = {
  label: '',
  type: 'text',
  field: '',
  isRequired: false,
  validators: [],
  placeholder: '',
  icon: null,
  showExtraInfo: false,
  infoMessage: '',
  disabled: false,
  onError: () => {},
  onChange: () => {},
  appendIcon: true,
  adjustIcon: false,
  newRefreshIcon: false
};

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
  };
};

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true },
)(InputField);
