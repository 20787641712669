import Validators from 'library/utilities/Validators';

export const addUserModel = [
  {
    label: 'userLabelText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'user',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'accountId',
    displayValue: [
      { key: 'lastName', separator: ', ' },
      { key: 'firstName', separator: '' },
    ],
    excludeData: [],
  },
  {
    label: 'roleLabelText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'role',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'role', separator: '' }],
  },
  {
    label: 'costCenter',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'costCenter',
    required: false,
    styleClass: 'col-md-4',
    isDisplayed: false,
  },
];

export const filterModel = [
  {
    label: '',
    value: '',
    type: 'text',
    placeholder: 'searchForUsernameText',
    field: 'username',
    required: false,
    styleClass: 'col-md-6 search-box',
    icon: 'search',
    adjustIcon: true,
  },
  {
    label: 'filterByRoleText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'role',
    required: false,
    styleClass: 'col-md-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    clearOption: true,
    displayValue: [{ key: 'role', separator: '' }],
    selectAllOption: true,
    selectAllPlaceholder: 'pleaseSelect'
  },
];
