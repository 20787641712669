import React, { Fragment, useState } from 'react';
import cn from 'classnames';

import Icon from '../Icon/Icon';
import './appTable.styles.scss';
import { ChevronDownIcon } from '../Icon/SvgIcons/Icons';
import classNames from 'classnames';

const Table = ({
  user,
  rows,
  cols,
  sortFields,
  handleSort,
  dataExpandableTable,
  handleDelete,
  saveCancellationRequest,
  children,
  dontShowExpandArrow = false,
}) => {
  const [dropdownIds, setDropdownId] = useState({});

  const hadleDropdown = index => {
    const ids = { ...dropdownIds };

    ids[index] = !ids[index];
    setDropdownId(ids);
  };

  return (
    <div className='table-responsive app-custom-table'>
      <table className='table table-borderless'>
        <thead>
          <tr>
            {cols.map(col => (
              <th
                key={col.name}
                data-test={col.isSort ? 'expandable-table-header-column-sort' : 'expandable-table-header-column'}
                className={col.isHidden ? 'd-none' : ''}
                onClick={col.isSort ? handleSort.bind(this, col.name) : null}
              >
                <div className='d-flex'>
                  <span className='mr-1'>{col.title}</span>
                  {col.isSort ? (
                    <div>
                      <div className={cn('triangle-up', { 'active-triangle-up': sortFields[col.name] === 'asc' })} />
                      <div
                        className={cn('triangle-down', { 'active-triangle-down': sortFields[col.name] === 'desc' })}
                      />
                    </div>
                  ) : null}
                </div>
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {rows &&
            rows.map((row, index) => (
              <Fragment key={row.id || row.kitInformationId || index}>
                <tr
                  data-test='expandable-content-row'
                  className='rows-divider'
                  onClick={() => hadleDropdown(row.id || row.kitInformationId || index)}
                >
                  {cols.map(col => {
                    if (!col.isHidden) {
                      return (
                        <td key={col.name}>
                          {col.render(row, index, !!dropdownIds[row.id || row.kitInformationId || index])}
                        </td>
                      );
                    }
                  })}
                  {!dontShowExpandArrow && (
                    <td className='text-right'>
                      {/* <Icon
                      name='arrow'
                      width={30}
                      height={25}
                      fill='#0088D0'
                      className='expandable-chevron'
                      style={{
                        transform: dropdownIds[row.id || row.kitInformationId || index]
                          ? 'rotate(-90deg)'
                          : 'rotate(90deg)',
                      }}
                    /> */}
                      <ChevronDownIcon
                        className={classNames('expandable-chevron', {
                          'rotate-up': dropdownIds[row.id || row.kitInformationId || index]
                        })}
                      />
                    </td>
                  )}
                </tr>
                <tr
                  className={cn('show-border-spacing', {
                    open: dropdownIds[row.id || row.kitInformationId || index],
                    close: !dropdownIds[row.id || row.kitInformationId || index],
                  })}
                >
                  {dataExpandableTable && dataExpandableTable(row, handleDelete, user, saveCancellationRequest, index)}

                  {children &&
                    React.Children.map(children, child => {
                      const props = { row, user, index };
                      if (React.isValidElement(child)) {
                        return React.cloneElement(child, props);
                      }

                      return child;
                    })}
                </tr>
              </Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
