import React, { useEffect, useState } from 'react';
import Assignment from './Components/Assignment/Assgnment.component';
import Confirmation from './Components/Confirmation/Confirmation.component';
import strings from 'resources/locales/Translate';
import Steps from 'library/common/commonComponents/Steps/Steps';
import './semBooking.style.scss';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import * as actionTypes from 'library/common/commonConstants/storeConstants';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import { useSemBooking } from './SemBooking.hook';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { containsIgnoreCase } from 'library/utilities/stringUtils';
import { BORROWER, DISPATCHER, READ_ONLY_USER, STOCK_CONTROLLER, ZMCC_OPERATOR } from 'library/utilities/constants';


const SemBooking = props => {
  const {
    activeStep,
    setActiveStep,
    goToPrevStep,
    saveZmccSemData,
    onContinueBooking,
    onCancelClick,
    cancelSemZmccLoanData,
    isLoading,
    setIsLoading,
    isXbBooking,
    isSemBooking,
    isXRMBooking,
    isLMBooking,
    zmccXrmBooking,
    zmccLmBooking,
    zmccXbBooking,
    zmccSemBooking,
    unsavedChanges,
    setUnsavedChanges,
    editCompletedBy,
    setEditCompltedBy
  } = useSemBooking(props);

const { generalInformationForm, customerDetilsForm, customerBackgroundForm } = props.formValue;
const warehouseName = generalInformationForm && generalInformationForm.formData && generalInformationForm.formData.stockInformationIdFullValue
  const {type, displayActionMessage,message, showAsIs} = props.appActions;
  let isZmcc = props.match && props.match.url.includes('isZmcc');

const checkAccessForSaving = () => {
  const {user} = props;
  let value = true
  const roles = [STOCK_CONTROLLER, DISPATCHER, ZMCC_OPERATOR, BORROWER];
  if (user?.admin) {
    return true;
  }
  if (user?.stockRoles?.length > 0) {
      user.stockRoles.forEach(element => {
        if (roles.includes(element.roleKey) && element.businessUnitName === 'RMS (ZMCC)' && element.stockId === generalInformationForm?.formData?.stockInformationId) {
          value = true;
        }else if (element.roleKey === READ_ONLY_USER && element.stockId === generalInformationForm?.formData?.stockInformationId) {
          value = false
        }
      });
  }
  return value;
}

  useEffect(() =>{
    const reloadCountPage = sessionStorage.getItem('reloadCountPage');

    if(reloadCountPage < 1) {
      sessionStorage.setItem('reloadCountPage', String(reloadCountPage + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCountPage');
    }
  },[props.match.id,props.match.url ])
  useEffect(() => {
    props.fetchMasterData(URLS.zmccCountryAndStocksList, actionTypes.FETCH_RMS_ZMCC_COUNTRIES);
    if (!props.usersList || !props.usersList.length) {
      props.fetchMasterData(URLS.userList, actionTypes.FETCH_USERS_LIST);
    }
    return () => {
      props.resetFormData();
  }
  }, []);

  useEffect(() =>{
    props.fetchLoansIntended();
    props.fetchBestWayToReach();
    props.fetchDataWithin();
    props.fetchDataWithCustomer();
    props.fetchRelationShipWithZeiss();
    props.fetchMarketSegment();
    props.fetchStatusOFSamples();
    props.fetchSkillLevel();
    props.fetchEstimatedDays();
    props.fetchMarketSubSegment();
    props.getSystemCategoriesList();
    props.fetchSbuTypes();
  }, [props.language])

  useEffect(() => {
    if(customerBackgroundForm.formData.marketSegmentId)
      props.fetchMarketSubSegment(customerBackgroundForm.formData.marketSegmentId || 0);
  }, [customerBackgroundForm.formData.marketSegmentId]);

  useEffect(() =>{
    const transactionsId = props.match.params && props.match.params.id;
    const crmData = fetchFromStorage(identifiers.zmccCrmParams);
    if (!isZmcc && crmData) {
      let marketSubSegment;
      if (props.marketSubSegments && props.marketSubSegments.length > 0) {
        marketSubSegment = props.marketSubSegments.find(item => crmData.marketSubsegment && containsIgnoreCase(crmData.marketSubsegment, item.staticCode));
      }
      props.getUnexecutedSemTransaction(props.user, props.marketSegments, transactionsId, marketSubSegment);
    }
  },[props.marketSegments, props.marketSubSegments])

  useEffect(() =>{
    const transactionsId = props.match.params && props.match.params.id;
    const crmData = fetchFromStorage(identifiers.zmccCrmParams);
    if (!isZmcc && (crmData === null || crmData === undefined || crmData === '') ) {
      props.getUnexecutedSemTransaction(props.user, props.marketSegments, transactionsId);
    }
  },[])

  
const hasAccess = checkAccessForSaving();

  return (
    <div className='single-loan-container'>
      {!isZmcc && displayActionMessage && <ActionMessage type={type} message={showAsIs ? message : (strings[message] || message)} />}
      {!isZmcc && <Steps activeStep={activeStep} actionFrom='zmccSemBooking' forZmccBooking={'zmcc-border-steps'} />}
      <div className='container-fluid zmcc-form-title-heading'>{strings.zmccFormName((isXbBooking || zmccXbBooking)? 'XB': (isLMBooking || zmccLmBooking)? 'LM': (isXRMBooking || zmccXrmBooking)? 'XRM': (isSemBooking ||zmccSemBooking) ? "SEM": '')} {customerDetilsForm.formData.customerName ? `${strings.for} ${customerDetilsForm.formData.customerName}`: ''}</div>
      {warehouseName && warehouseName.locationName && <div className='container-fluid warehouse-style'>@ZMCC {warehouseName.locationName}</div>}
      <div className='container-fluid mt-3 '>
        {activeStep === 1 &&
          <Assignment
            {...props}
            setActiveStep={setActiveStep}
            goToPrevStep={goToPrevStep}
            saveZmccSemData={saveZmccSemData}
            onContinueBooking={onContinueBooking}
            onCancelClick={onCancelClick}
            cancelSemZmccLoanData={cancelSemZmccLoanData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isZmcc={isZmcc}
            isXbBooking={isXbBooking}
            isSemBooking={isSemBooking}
            isXRMBooking={isXRMBooking}
            isLMBooking={isLMBooking}
            zmccXrmBooking={zmccXrmBooking}
            zmccLmBooking={zmccLmBooking}
            zmccXbBooking={zmccXbBooking}
            zmccSemBooking={zmccSemBooking}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            editCompletedBy={editCompletedBy}
            setEditCompltedBy={setEditCompltedBy}
            hasAccess={hasAccess}
          />
        }
        {!isZmcc && activeStep === 2 && (
          <Confirmation
            setActiveStep={setActiveStep}
            goToPrevStep={goToPrevStep}
            saveZmccSemData={saveZmccSemData}
            onContinueBooking={onContinueBooking}
            onCancelClick={onCancelClick}
            cancelSemZmccLoanData={cancelSemZmccLoanData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            {...props}
            history={props.history}
            hasAccess={hasAccess}
          />
        )}
      </div>
      {/* {isLoading && <div className='loader' />} */}
    </div>
  );
};

export default SemBooking;
