/**
 * This has been as added to return direct svg where we do not have single path icons [Use Icon component if there is single path in svg]
 * If there are multiple paths, complext elements like rect etc. Return direct svg a single component to reuse.
 */
import React from 'react';

export const MessageOutlineIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 17 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.325 1.5H2.77499C2.0156 1.5 1.39999 2.11561 1.39999 2.875V11.125C1.39999 11.8844 2.0156 12.5 2.77499 12.5H14.325C15.0844 12.5 15.7 11.8844 15.7 11.125V2.875C15.7 2.11561 15.0844 1.5 14.325 1.5Z'
        stroke='#0088D0'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.59999 3.69995L8.54999 7.54995L13.5 3.69995'
        stroke='#0088D0'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const EditOutlineIcon = ({
  stroke = '#0088D0',
  width = '18',
  height = '18',
  viewBox = '0 0 16 16',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M1.42757 13.4697L1.85411 10.5451C1.88011 10.3539 1.96724 10.1761 2.10357 10.0391L10.368 1.76347C10.4751 1.65523 10.6083 1.57635 10.7548 1.53439C10.9012 1.49243 11.0559 1.4888 11.2042 1.52386C11.9627 1.73391 12.6504 2.14522 13.1942 2.71422C13.7616 3.26181 14.1701 3.95292 14.3761 4.71409C14.4112 4.86233 14.4076 5.01708 14.3656 5.16351C14.3236 5.30994 14.2448 5.44313 14.1365 5.5503L5.86299 13.8154C5.72561 13.9512 5.54776 14.0385 5.35634 14.0642L2.43243 14.4907C2.29431 14.5108 2.15338 14.4982 2.02108 14.4537C1.88879 14.4092 1.76884 14.3341 1.67096 14.2346C1.57309 14.1351 1.50004 14.0139 1.45776 13.8809C1.41547 13.7479 1.40513 13.6067 1.42757 13.469M8.80447 3.33751L12.573 7.10607'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const DocumentsOutlineIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.700012 1.375C0.700012 1.20924 0.76586 1.05027 0.883071 0.933058C1.00028 0.815848 1.15925 0.75 1.32501 0.75H7.57501L10.7 3.875V12.625C10.7 12.7908 10.6342 12.9497 10.517 13.0669C10.3997 13.1842 10.2408 13.25 10.075 13.25H1.32501C1.15925 13.25 1.00028 13.1842 0.883071 13.0669C0.76586 12.9497 0.700012 12.7908 0.700012 12.625V1.375Z'
        stroke='#0088D0'
        strokeWidth='1.2'
        strokeLinejoin='round'
      />
      <path
        d='M3.20002 5.75H8.20002M3.20002 8.25H8.20002'
        stroke='#0088D0'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const DeleteIcon = ({
  width = '18',
  height = '18',
  viewBox = '0 0 16 16',
  className = '',
}) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.0344 1.95C12.2068 1.95 12.3721 2.01848 12.494 2.14038C12.6159 2.26228 12.6844 2.42761 12.6844 2.6C12.6844 2.77239 12.6159 2.93772 12.494 3.05962C12.3721 3.18152 12.2068 3.25 12.0344 3.25H11.3844L11.3824 3.29615L10.776 11.7923C10.7526 12.1203 10.6059 12.4272 10.3652 12.6513C10.1246 12.8754 9.80803 13 9.47923 13H4.18887C3.86007 13 3.54347 12.8754 3.30285 12.6513C3.06223 12.4272 2.91547 12.1203 2.89212 11.7923L2.28567 3.2968C2.28469 3.28122 2.28426 3.26561 2.28437 3.25H1.63437C1.46198 3.25 1.29665 3.18152 1.17476 3.05962C1.05286 2.93772 0.984375 2.77239 0.984375 2.6C0.984375 2.42761 1.05286 2.26228 1.17476 2.14038C1.29665 2.01848 1.46198 1.95 1.63437 1.95H12.0344ZM10.0824 3.25H3.58632L4.18953 11.7H9.47923L10.0824 3.25ZM8.13437 0C8.30677 0 8.4721 0.0684819 8.59399 0.190381C8.71589 0.312279 8.78437 0.477609 8.78437 0.65C8.78437 0.822391 8.71589 0.987721 8.59399 1.10962C8.4721 1.23152 8.30677 1.3 8.13437 1.3H5.53437C5.36198 1.3 5.19665 1.23152 5.07476 1.10962C4.95286 0.987721 4.88437 0.822391 4.88437 0.65C4.88437 0.477609 4.95286 0.312279 5.07476 0.190381C5.19665 0.0684819 5.36198 0 5.53437 0H8.13437Z'
        fill='#C91803'
      />
    </svg>
  );
};

export const ChevronDownIcon = ({ className = '', style = {} }) => (
  <svg
    width='15'
    height='8'
    viewBox='0 0 15 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    style={{ ...style }}
  >
    <path
      d='M7.48138 7.80618L1.01295 1.01737C0.904965 0.904093 0.844727 0.7536 0.844727 0.597102C0.844727 0.440604 0.904965 0.290111 1.01295 0.176836L1.02026 0.169527C1.07261 0.114423 1.13562 0.0705457 1.20546 0.0405622C1.2753 0.0105786 1.35051 -0.00488281 1.42651 -0.00488281C1.50252 -0.00488281 1.57773 0.0105786 1.64757 0.0405622C1.71741 0.0705457 1.78042 0.114423 1.83277 0.169527L7.92358 6.56244L14.0119 0.169527C14.0643 0.114423 14.1273 0.0705457 14.1971 0.0405622C14.267 0.0105786 14.3422 -0.00488281 14.4182 -0.00488281C14.4942 -0.00488281 14.5694 0.0105786 14.6393 0.0405622C14.7091 0.0705457 14.7721 0.114423 14.8245 0.169527L14.8318 0.176836C14.9398 0.290111 15 0.440604 15 0.597102C15 0.7536 14.9398 0.904093 14.8318 1.01737L8.36333 7.80618C8.30645 7.86588 8.23803 7.91342 8.16223 7.94589C8.08643 7.97837 8.00482 7.99512 7.92236 7.99512C7.83989 7.99512 7.75829 7.97837 7.68249 7.94589C7.60669 7.91342 7.53827 7.86588 7.48138 7.80618Z'
      fill='#0088D0'
    />
  </svg>
);

export const DeleteFilledIcon = ({ fill = '#C91803', className="" }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='17' height='20' viewBox='0 0 14 13' fill='none' className={className}>
    <path
      d='M7.4841 2.11815e-08C8.05958 -7.49597e-05 8.6143 0.198923 9.03934 0.557921C9.46439 0.916919 9.72905 1.40998 9.78136 1.9403H13.3552C13.5145 1.94034 13.668 1.99637 13.7844 2.09705C13.9009 2.19774 13.9717 2.33558 13.9825 2.48271C13.9934 2.62985 13.9435 2.77532 13.8429 2.88972C13.7424 3.00413 13.5986 3.07894 13.4407 3.09904L13.3552 3.10448H12.8662L11.8035 11.6263C11.7562 12.0045 11.5603 12.3535 11.253 12.6068C10.9457 12.8601 10.5485 13.0001 10.137 13H4.83123C4.41971 13.0001 4.02252 12.8601 3.71524 12.6068C3.40795 12.3535 3.21201 12.0045 3.16469 11.6263L2.10119 3.10448H1.61305C1.46104 3.10447 1.31418 3.05353 1.19962 2.96108C1.08506 2.86862 1.01055 2.74091 0.98988 2.60155L0.984009 2.52239C0.984015 2.38173 1.03906 2.24582 1.13898 2.13982C1.23889 2.03381 1.37691 1.96486 1.5275 1.94573L1.61305 1.9403H5.18685C5.23916 1.40998 5.50382 0.916919 5.92887 0.557921C6.35391 0.198923 6.90863 -7.49597e-05 7.4841 2.11815e-08ZM6.22602 4.85075C6.02053 4.85075 5.8486 4.97105 5.81337 5.12937L5.80666 5.19069V9.94442L5.81337 10.005C5.8486 10.1633 6.02053 10.2836 6.22602 10.2836C6.43151 10.2836 6.60345 10.1633 6.63867 10.005L6.64538 9.94364V5.19146L6.63867 5.12937C6.60345 4.97182 6.43151 4.85075 6.22602 4.85075ZM8.74219 4.85075C8.5367 4.85075 8.36476 4.97105 8.32954 5.12937L8.32283 5.19069V9.94442L8.32954 10.005C8.36476 10.1633 8.5367 10.2836 8.74219 10.2836C8.94767 10.2836 9.11961 10.1633 9.15484 10.005L9.16155 9.94364V5.19146L9.15484 5.12937C9.11961 4.97182 8.94767 4.85152 8.74219 4.85152V4.85075ZM7.4841 1.16418C6.97668 1.16418 6.55312 1.49791 6.45667 1.9403H8.51154C8.41425 1.49791 7.99153 1.16418 7.4841 1.16418Z'
      fill={fill}
    />
  </svg>
);

export const DustCommentArrowIcon = () => (
  <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 4.5L16 8.5M16 8.5L12 12.5M16 8.5H5C3.93913 8.5 2.92172 8.07857 2.17157 7.32843C1.42143 6.57828 1 5.56087 1 4.5C1 3.43913 1.42143 2.42172 2.17157 1.67157C2.92172 0.921427 3.93913 0.5 5 0.5H6" stroke="#0088D0" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export const StockControllerDelete = ({fill = '#41464F'}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6462_180)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1.33301C10.3682 1.33301 10.6667 1.63148 10.6667 1.99967L10.6664 2.66601L14.2394 2.66634C14.388 2.66634 14.4419 2.68181 14.4962 2.71086C14.5505 2.73991 14.5931 2.78255 14.6222 2.83687C14.6512 2.89119 14.6667 2.94507 14.6667 3.09366V3.57236C14.6667 3.72095 14.6512 3.77483 14.6222 3.82915C14.5931 3.88347 14.5505 3.9261 14.4962 3.95515C14.4419 3.9842 14.388 3.99967 14.2394 3.99967L13.333 3.99934L13.3334 13.9997C13.3334 14.3679 13.0349 14.6663 12.6667 14.6663H3.33337C2.96518 14.6663 2.66671 14.3679 2.66671 13.9997L2.66637 3.99934L1.76069 3.99967C1.6121 3.99967 1.55822 3.9842 1.5039 3.95515C1.44958 3.9261 1.40695 3.88347 1.3779 3.82915C1.34884 3.77483 1.33337 3.72095 1.33337 3.57236V3.09366C1.33337 2.94507 1.34884 2.89119 1.3779 2.83687C1.40695 2.78255 1.44958 2.73991 1.5039 2.71086C1.55822 2.68181 1.6121 2.66634 1.76069 2.66634L5.33304 2.66601L5.33337 1.99967C5.33337 1.63148 5.63185 1.33301 6.00004 1.33301H10ZM11.9997 3.99919H3.99969L4.00003 13.3329H12L11.9997 3.99919ZM7.00003 5.99951C7.18412 5.99951 7.33336 6.14875 7.33336 6.33284V10.9995C7.33336 11.1836 7.18412 11.3328 7.00003 11.3328H6.33336C6.14927 11.3328 6.00003 11.1836 6.00003 10.9995V6.33284C6.00003 6.14875 6.14927 5.99951 6.33336 5.99951H7.00003ZM9.6667 5.99951C9.85079 5.99951 10 6.14875 10 6.33284V10.9995C10 11.1836 9.85079 11.3328 9.6667 11.3328H9.00003C8.81594 11.3328 8.6667 11.1836 8.6667 10.9995V6.33284C8.6667 6.14875 8.81594 5.99951 9.00003 5.99951H9.6667Z" fill={fill}/>
      </g>
      <defs>
      <clipPath id="clip0_6462_180">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
</svg>
)

export const CheckMark = () => (
  <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.9585 15.5003L3.2085 10.7503L4.396 9.56283L7.9585 13.1253L15.6043 5.47949L16.7918 6.66699L7.9585 15.5003Z" fill="#0088D0"/>
</svg>
)

export const CrossIcon = () => (
  <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.33317 16.3337L4.1665 15.167L8.83317 10.5003L4.1665 5.83366L5.33317 4.66699L9.99984 9.33366L14.6665 4.66699L15.8332 5.83366L11.1665 10.5003L15.8332 15.167L14.6665 16.3337L9.99984 11.667L5.33317 16.3337Z" fill="#1D1B20"/>
</svg>
)

export const NewRefreshIcon = () => (
<svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.8349 1.16699L8.83491 1.16699M8.83491 1.16699L8.83491 5.16699M8.83491 1.16699L11.7416 4.26033C12.4584 4.97682 12.9821 5.86324 13.2637 6.83686C13.5453 7.81049 13.5757 8.83959 13.3521 9.82813C13.1284 10.8167 12.658 11.7325 11.9846 12.49C11.3113 13.2476 10.4571 13.8222 9.50158 14.1603M2.16824 15.8337L6.16824 15.8337M6.16824 15.8337L6.16824 11.8337M6.16824 15.8337L3.26158 12.7403C2.54472 12.0238 2.02105 11.1374 1.73943 10.1638C1.4578 9.19016 1.42741 8.16106 1.65108 7.17252C1.87475 6.18397 2.3452 5.26819 3.01852 4.51064C3.69185 3.75308 4.5461 3.17844 5.50158 2.84033" stroke="#0088D0" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)

export const LeftArrowIcon = ({
  fill = '#9FA0A1',
  width = '20',
  height = '25',
  viewBox = '0 0 12 20',
  className = '',
}) =>{
  return(
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.36699 6.97189L8.51905 0.415378C8.811 0.149331 9.20592 0 9.61757 0C10.0292 0 10.4241 0.149331 10.7161 0.415378C10.8621 0.548169 10.9781 0.706155 11.0572 0.880223C11.1363 1.05429 11.177 1.24099 11.177 1.42957C11.177 1.61814 11.1363 1.80484 11.0572 1.97891C10.9781 2.15298 10.8621 2.31096 10.7161 2.44375L3.54845 8.98598C3.4024 9.11877 3.28648 9.27675 3.20738 9.45082C3.12827 9.62489 3.08754 9.81159 3.08754 10.0002C3.08754 10.1887 3.12827 10.3754 3.20738 10.5495C3.28648 10.7236 3.4024 10.8816 3.54845 11.0144L10.7161 17.5566C11.0095 17.8237 11.1752 18.1867 11.1766 18.5657C11.1781 18.9448 11.0152 19.3088 10.7239 19.5778C10.4325 19.8468 10.0366 19.9987 9.62308 20C9.2096 20.0013 8.81247 19.852 8.51905 19.5849L1.36699 13.0284C0.491601 12.2249 -9.91821e-05 11.1358 -9.91821e-05 10.0002C-9.91821e-05 8.86456 0.491601 7.77538 1.36699 6.97189Z" fill={fill}/>
  </svg>
  )
}

export const RightArrowIcon = ({
  fill = '#9FA0A1',
  width = '20',
  height = '25',
  viewBox = '0 0 12 20',
  className = '',
}) =>{
  return(
  <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.98701 6.97189L2.83495 0.415378C2.54301 0.149331 2.14808 0 1.73643 0C1.32478 0 0.929855 0.149331 0.63791 0.415378C0.491864 0.548169 0.375944 0.706155 0.296837 0.880223C0.217731 1.05429 0.177002 1.24099 0.177002 1.42957C0.177002 1.61814 0.217731 1.80484 0.296837 1.97891C0.375944 2.15298 0.491864 2.31096 0.63791 2.44375L7.80556 8.98598C7.9516 9.11877 8.06752 9.27675 8.14663 9.45082C8.22574 9.62489 8.26646 9.81159 8.26646 10.0002C8.26646 10.1887 8.22574 10.3754 8.14663 10.5495C8.06752 10.7236 7.9516 10.8816 7.80556 11.0144L0.63791 17.5566C0.344498 17.8237 0.17884 18.1867 0.177379 18.5657C0.175918 18.9448 0.338775 19.3088 0.63012 19.5778C0.921466 19.8468 1.31744 19.9987 1.73092 20C2.14441 20.0013 2.54154 19.852 2.83495 19.5849L9.98701 13.0284C10.8624 12.2249 11.3541 11.1358 11.3541 10.0002C11.3541 8.86456 10.8624 7.77538 9.98701 6.97189Z" fill={fill}/>
  </svg>)
}
