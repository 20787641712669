import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import React, { useEffect, useCallback, useState } from 'react';
import strings from 'resources/locales/Translate';
import {
    CheckMark,
    CrossIcon,
    EditOutlineIcon,
    StockControllerDelete,
  } from 'library/common/commonComponents/Icon/SvgIcons/Icons';
import Button from 'library/common/commonComponents/Button/Button';
import { newComponentsModel } from './NewComponents.constants';
import CreateZMCCKitReducer from '../../CreateZmccKit.reducer';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';
import { UPDATE_ZMCC_KIT_FORM } from '../../CreateZmccKit.constants';

export const useNewComponents = ({
    kitId,
    addUpdateNewComponent,
    fetchNewComponentsList,
    deleteNewComponent,
    newComponentsList,
    canEditKit,
    formValue,
    updateForm,
  }) => {

    const [searchData, setSearchData] = useState({ searchText: '' });
    const [editingFileds, setEditingFields] = useState([-1]);    
    const [newComponentsUpdatedList, setNewComponentsUpdatedList] = useState(newComponentsList);
    

    const onAddComponentClick = () => {
      checkNewComponentsForm();
    }
  
    const checkNewComponentsForm = async () => {
      const validForm = validateForm({ form: formValue.newComponentsForm, model: newComponentsModel });

      if (!validForm.isFormValid) {
        return updateForm({type: UPDATE_ZMCC_KIT_FORM ,newComponentsForm : validForm});
      } else{
        if (kitId){
          const {success} = await addUpdateNewComponent({...formValue.newComponentsForm.formData, id: null, "kitInformationId": kitId});
          if (success){
            fetchNewComponentsList(kitId);
          }
          updateForm({type: UPDATE_ZMCC_KIT_FORM , newComponentsForm : {formData: {},isFormValid: false}});
        } else{
          setNewComponentsUpdatedList([...newComponentsUpdatedList, {...formValue.newComponentsForm.formData}])
          Object.keys(formValue.newComponentsForm.formData).forEach(key => {
            if (key.includes('Error') || key.includes('FullValue')) {
              delete formValue.newComponentsForm.formData[key];
            }
          });
          updateForm({type: UPDATE_ZMCC_KIT_FORM , newComponentsForm : {formData: {},isFormValid: false}, newComponentsList: [...newComponentsUpdatedList, {...formValue.newComponentsForm.formData, id: newComponentsUpdatedList?.length +1, "kitInformationId": kitId, newStateComponent: true}]})
        }

      }
    }

    useEffect(() => {
      if (kitId){
        fetchNewComponentsList(kitId);
      }
    }, [fetchNewComponentsList,kitId]);

    let emptyNewComponent = {
        id: -1,
        componentsNumber: '',
        componentsName: '',
        componentMaterialNumber: '',
        componentSerialNumber: '',
    };

    const checkValidation = (id, filterData) => {
      
        let isValid = true;
        let validObj = {};
        if (filterData[0].componentNumber === '') {
          validObj = { componentNumberError: 'requiredErrorMessage' };
          isValid = false;
        }
        if (filterData[0].componentName === '') {
          validObj = { ...validObj, componentNameError: 'requiredErrorMessage' };
          isValid = false;
        }
    
        const changedValues = newComponentsUpdatedList.map(item => {
          if (item.id === id) {
            return { ...item, ...validObj };
          }
          return item;
        });
        setNewComponentsUpdatedList(changedValues);
    
        return isValid;
      };

      const onSaveEditData = async id => {
        const filterData = newComponentsUpdatedList.filter(item => item.id === id);
        if (!checkValidation(id, filterData)) {
          return;
        }
        const dataToSend = {
          componentNumber: filterData[0].componentNumber,
          componentName: filterData[0].componentName,
          materialNumber: filterData[0].materialNumber,
          serialNumber: filterData[0].serialNumber,
          kitInformationId: kitId,
          id: filterData[0].id,
        };
        if (kitId){
          await addUpdateNewComponent(dataToSend);
          const removedEditingField = editingFileds.filter(item => item !== id);
          setEditingFields(removedEditingField);
        } else{
          updateForm({type: UPDATE_ZMCC_KIT_FORM , newComponentsList: [...newComponentsUpdatedList]})
          const removedEditingField = editingFileds.filter(item => item !== id);
          setEditingFields(removedEditingField);
        }

      };
    
      const onCancelClick = id => {
        
        const removedEditingField = editingFileds.filter(item => item !== id);
        
        setEditingFields(removedEditingField);
        setNewComponentsUpdatedList(newComponentsList);
      };



    const onEditClick = (item,id) => {
      setEditingFields(prevSate => [...prevSate, id]);
    };

    const onDeleteClick  = async (id, kitId) =>{
      if (kitId){
        const {success} = await deleteNewComponent(id,kitId);
        
        if (success){
          fetchNewComponentsList(kitId);
        }
      } else{
        const filterData = newComponentsUpdatedList.filter(item => item.id !== id);
        setNewComponentsUpdatedList(filterData);
      }

    }

    useEffect(() => {
      setNewComponentsUpdatedList(newComponentsList);
    }, [newComponentsList]);

    const onChange = (e, id, field) => {
      const changedValues = newComponentsUpdatedList.map(item => {
        if (item.id === id) {
          delete [`${field}Error`];
          return { ...item, [field]: e };
        } else {
          return item;
        }
      });

      setNewComponentsUpdatedList(changedValues);
    };

    const getCols = () => [
        {
          title: strings.number,
          name: 'componentNumber',
          isSort: false,
          render: item => {
            return (
              <span>
                {editingFileds.includes(item.id) ? (
                  <InputField
                    label={''}
                    field='componentNumber'
                    placeholder={''}
                    isRequired={true}
                    type='text'
                    validators={[]}
                    value={item.componentNumber}
                    error={item.componentNumberError}
                    onChange={e => onChange(e, item.id, 'componentNumber')}
                  />
                ) : (
                  item.componentNumber
                )}
              </span>
            );
          },
        },
        {
            title: strings.name,
            name: 'componentName',
            isSort: false,
            render: item => {
              return (
                <span>
                  {editingFileds.includes(item.id) ? (
                    <InputField
                      label={''}
                      field='componentName'
                      placeholder={''}
                      isRequired={true}
                      type='text'
                      validators={[]}
                      value={item.componentName}
                      error={item.componentNameError}
                      onChange={e => onChange(e, item.id, 'componentName')}
                    />
                  ) : (
                    item.componentName
                  )}
                </span>
              );
            },
          },
          {
            title: strings.materialNumber,
            name: 'materialNumber',
            isSort: false,
            render: item => {
              return (
                <span>
                  {editingFileds.includes(item.id) ? (
                    <InputField
                      label={''}
                      field='materialNumber'
                      placeholder={''}
                      isRequired={true}
                      type='text'
                      validators={[]}
                      value={item.materialNumber}
                      error={item.materialNumberError}
                      onChange={e => onChange(e, item.id, 'materialNumber')}
                    />
                  ) : (
                    item.materialNumber
                  )}
                </span>
              );
            },
          },
          {
            title: strings.serialNumber,
            name: 'serialNumber',
            isSort: false,
            render: item => {
              return (
                <span>
                  {editingFileds.includes(item.id) ? (
                    <InputField
                      label={''}
                      field='serialNumber'
                      placeholder={''}
                      isRequired={true}
                      type='text'
                      validators={[]}
                      value={item.serialNumber}
                      error={item.serialNumberError}
                      onChange={e => onChange(e, item.id, 'serialNumber')}
                    />
                  ) : (
                    item.serialNumber
                  )}
                </span>
              );
            },
          },
          {
            title: '',
            name: 'deleteOrEdit',
            isSort: false,
            render: item => {
              return (
                <span>
                  {!editingFileds.includes(item.id) && canEditKit && (
                    <div className='adjust-delete-edit-icon cursor-pointer'>
                      <div onClick={() => onEditClick(item, item.id)}>
                        <EditOutlineIcon width={16} height={16} />
                      </div>
                      <div onClick={() => onDeleteClick(item.id, kitId)}>
                        <StockControllerDelete fill='#0088D0' />
                      </div>
                    </div>
                  )}
                  {editingFileds.includes(item.id) && item.id !== -1 && canEditKit && (
                    <div className='adjust-delete-edit-icon mt-3 cursor-pointer'>
                      <div onClick={() => onSaveEditData(item.id)}>
                        <CheckMark />
                      </div>
                      <div onClick={() => onCancelClick(item.id)}>
                        <CrossIcon />
                      </div>
                    </div>
                  )}
                </span>
              );
            },
          },
      ];

    
      return {
        getCols,
        newComponentsUpdatedList,
        onAddComponentClick
      };
}