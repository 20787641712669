import Validators from 'library/utilities/Validators';
import strings from 'resources/locales/Translate';

export const filtersModel = [
  {
    label: '',
    value: '',
    type: 'text',
    placeholder: 'searchForUsernameText',
    field: 'username',
    required: false,
    styleClass: 'col-md-5 search-box',
    icon: 'search',
  },
  {
    label: 'filterByRoleText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'role',
    required: false,
    styleClass: 'col-md-5',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'role', separator: '' }],
    clearOption: true,
    selectAllOption: true,
    selectAllPlaceholder: 'clearAll',
  },
];

export const userPermissionModel = [
  {
    label: 'userLabelText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'accountId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'accountId',
    displayValue: [
      { key: 'lastName', separator: ', ' },
      { key: 'firstName', separator: '' },
    ],
    excludeData: [],
  },
  {
    label: 'roleLabelText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'role',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'role', separator: '' }],
  },
];

export const roles = [
  {
    id: 'DUST_USER',
    role: strings.dustUser,
  },
  {
    id: 'DUST_MANAGER',
    role: strings.dustManager,
  },
];

export const permissionstBreadcrumbData = [
  {
    name:'homepage',
    url: '/home',
  },
  {
    name:'Demo Unit Status Tool',
    url: '/administration/permissions'
  },
  {
    name:'dusdPermissions',
  },
];

export const GET_PERMISSIONS_LIST = 'PERMISSIONS/GET_PERMISSIONS_LIST';
