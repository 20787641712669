import React from 'react';
import Section from '../Section/Section';
import "./keyValue.styles.scss";
import Button from 'library/common/commonComponents/Button/Button';
import { NavLink } from 'react-router-dom';


const KeyValueDetails = ({ details, sectionTitle, styleColClass='col-4', children}) => {
  const columnsPerRow = 12 / parseInt(styleColClass.split('-')[1], 10);
  const rows = [];

  for (let i = 0; i < details.length; i += columnsPerRow) {
    rows.push(details.slice(i, i + columnsPerRow));
  }

  return (
    <div className='mb-3'>
      <Section sectionTitle={sectionTitle} className="col-12">
        {rows.map((row, rowIndex) => (
          <div key={rowIndex} className={`row ${rowIndex === rows.length - 1 ? '' : 'full-row mb-1'}`}>
            {row.map(({ key, value, buttonConfig , hasButton=false, hasNavLink=false, link='', navLinkValue='', extravalue =''}) => (
              <div key={key} className={`${styleColClass} p-3 pt-3`}>
                <div className='key-header'>{`${key}:`}</div>
                <div className={`value d-flex align-items-center ${hasButton ? 'attach-to-label' : 'mt-1' }`} >
                  <span>{value || '-'}</span> {hasNavLink && <span><NavLink to={link}>
                    {navLinkValue}
                  </NavLink> {extravalue}</span>}
                  {
                    hasButton && 
                    <span>
                      <Button
                        bgFill={false}
                        value={buttonConfig.label}
                        iconName={buttonConfig.icon}
                        onClick={buttonConfig.onClick}
                        newVersionUi={true}
                        styleClass="button-without-border"
                      />
                    </span>
                  }
                </div>
              </div>
            ))}
          </div>
        ))}
        {children && <div className='children-container row'>{children}</div>}
      </Section>
    </div>
  );
};

export default KeyValueDetails;