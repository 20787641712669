import React, { useEffect, useCallback, useState } from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import Attachments from 'library/common/commonComponents/AttachmentsForm';
import Accessories from 'library/common/commonComponents/AccessoriesForm';
import Components from 'library/common/commonComponents/ComponentsForm';
import NewCreateKitComponents from './Components/NewComponentsTab/NewComponents.component';
import InformationTab from './Components/InformationTab';
import { FETCH_ZMCC_BU_CONDITIONS } from './CreateZmccKit.constants';
import {FETCH_BUSINESS_UNIT_LIST, FETCH_COUNTRY_LIST} from 'library/common/commonConstants/storeConstants';
import VRDetailsForm from "../KitDetails/Components/VrDetails/VrDetails.componentForm";
import { IQS_SSC_DE_DESIGNATION } from 'library/utilities/constants';
import { IqsAttachment } from 'modules/Stock/CreateStock/Tabs/IqsAttachments/IqsAttachment.component';
import { getIqsSscAttachmentList } from 'modules/Stock/CreateStock/CreateStock.actions';
import KitMessagesTab from './Components/KitMessagesTab/KitMessagesTab.component';

export const useCreateZmccKit = props => {
  const {
    freeAccessoriesForm,
    furtherImportantComponentForm,
    salesComponentForm,
    location,
    updateForm,
    fetchStocks,
    createZmccKit,
    kitDetails,
    kitInformationId,
    kitAttachments,
    kitAttachmentsToDelete,
    kitImages,
    kitImagesToDelete,
    fetchMasterData,
    masterData,
    fetchZMCCKitDetails,
    resetForm,
    fetchSystemClasses,
    history,
    match,
    informationForm,
    businessUnitsData,
    rmsBuData,
    rmsIQSData,
    rooms,
    fetchUsersOfBusinessUnit,
    zmccUsers,
    language,
    vrDetalisForm,
    generalInformationIQSModel,
    generalInformationZMCCModel,
    fetchFunctionDropdownValues,
    resourceFunctionValues,
    iqsIdentifierForm,
    iqsStatusForm,
    newComponentsForm,
    addUpdateNewComponent,
    getAttachmentCategory,
    kitResourceAttachmentCategoriesList,
    fetchNewComponentsList,
    newComponentsList,
    toggleActionMessage,
    deleteNewComponent,
    createKitMessage,
    fetchKitMessagesList,
    kitMessagesList,
    fetchKitResourceAttachmentCategories,
    kitNewAttachments,
    getKitAttachmentList,
    changeLoader,
    deleteKitAttachment,
    fetchUserList
  } = props;
  
  const isCopy = location.pathname.includes('copy');
  const isEdit = location.pathname.includes('edit');
  const isKitCreating = location.pathname.includes('create');
  const [maxFilesError, setMaxFilesError] = useState("")
  const [gernalInformationModelEX, setGernalInformationModelEX] = useState(generalInformationZMCCModel);  
  const buDesignation = informationForm.formData?.businessUnitFullValue?.designation;
  useEffect(() => {
    return () => resetForm();
  }, [resetForm]);

  useEffect(() => {
    const buDesignation = informationForm.formData?.businessUnitFullValue?.designation;
    if (buDesignation === IQS_SSC_DE_DESIGNATION){
      informationForm.formData.businessUnit && fetchSystemClasses(informationForm.formData.businessUnit);
    } else{
      rmsBuData && fetchSystemClasses(rmsBuData["id"]);
    }
    if (informationForm.formData.businessUnit){
      fetchStocks(informationForm.formData.businessUnit);
      getAttachmentCategory()
    }
    informationForm["formData"] = kitDetails?.kitInformationId ? {
      ...informationForm.formData,
      businessUnit: informationForm?.formData?.businessUnit,
      businessUnitFullValue: informationForm.formData.businessUnitFullValue
    } : {
      businessUnit: informationForm?.formData?.businessUnit,
      businessUnitFullValue: informationForm.formData.businessUnitFullValue
    }

  }, [informationForm.formData.businessUnit, rmsBuData, language]);

  useEffect(() => {
    const buDesignation = informationForm.formData?.businessUnitFullValue?.designation;
    if (buDesignation === IQS_SSC_DE_DESIGNATION) {
      fetchFunctionDropdownValues();
      fetchMasterData(URLS.countryList, FETCH_COUNTRY_LIST);
      fetchKitResourceAttachmentCategories();
    }
  }, [informationForm.formData.businessUnit, fetchFunctionDropdownValues, language, fetchKitResourceAttachmentCategories]);

  useEffect(() => {
    const buDesignation = informationForm.formData?.businessUnitFullValue?.designation;
    if (buDesignation === IQS_SSC_DE_DESIGNATION) {
      let updatedModel = [...generalInformationIQSModel];
      updatedModel[2].options = resourceFunctionValues;
      setGernalInformationModelEX(updatedModel);
    } else {
      setGernalInformationModelEX(generalInformationZMCCModel);
    }
  }, [informationForm.formData.businessUnit, generalInformationIQSModel, generalInformationZMCCModel, resourceFunctionValues]);



  useEffect(() => {
    informationForm.formData.businessUnit && fetchUsersOfBusinessUnit(informationForm.formData.businessUnit);
  }, [informationForm.formData.businessUnit]);

  useEffect(() => {
    
    fetchMasterData(URLS.kitCondition, FETCH_ZMCC_BU_CONDITIONS);
    fetchMasterData(URLS.businessUnitList, FETCH_BUSINESS_UNIT_LIST);
  }, [fetchStocks, fetchSystemClasses, fetchMasterData, fetchUsersOfBusinessUnit, language]);


  useEffect(() => {
    if (isEdit || isCopy) {
    fetchZMCCKitDetails(match.params.id, isCopy);
    }
  }, [match.params.id,fetchZMCCKitDetails, isCopy, isEdit, language]);

  const getTitle = () => {

    if (isEdit) {
      if (buDesignation  === IQS_SSC_DE_DESIGNATION ){
        return strings.maintainResource;
      }
      return strings.maintainZmccKit;
    }
    if (isCopy) return strings.copyZmccKit;
    if (buDesignation  === IQS_SSC_DE_DESIGNATION ){
      return strings.createResource;
    }
    return strings.createZMCCKit;
  };

  const addKitImageFile = files => {
    const newImages = [...kitImages, ...files];
    setMaxFilesError("");
    updateForm({ kitImages: newImages });
  };

  const removeKitImageFile = index => {
    const fileToRemove = kitImages[index];
    let newKitImagesToDelete = [...kitImagesToDelete];
    setMaxFilesError("");
    if (fileToRemove.kitImagesId) {
      newKitImagesToDelete.push(fileToRemove.kitImagesId);
    }
    const newKitImages = kitImages.filter((_, i) => i !== index);
    updateForm({ kitImages: newKitImages, kitImagesToDelete: newKitImagesToDelete });
  };

  const handleForm = ({ values, formName, field }) => {
    if (field === 'kitCondition' && values.kitCondition === 5) {
      return openInRefurbishmentPopup();
    }
    
    if (field === 'stockInformation'){
      return updateForm({
        [formName]: {
          ...props[formName],
          formData: {
            ...props[formName].formData,
            ...values,
            room: '',
            roomFullValue: null,
          },
        },
      });
    }

    if (field === 'systemClass') {
      return updateForm({
        [formName]: {
          ...props[formName],
          formData: {
            ...props[formName].formData,
            ...values,
            systemMainComponent: null,
            systemMainComponentFullValue: null,
          },
        },
      });
    }
    updateForm({
      [formName]: {
        ...props[formName],
        formData: {
          ...props[formName].formData,
          ...values,
        },
      },
    });
  };

  const saveKit = async () => {
    const { success, kitInformationId, isIqsSscDeKit } = await createZmccKit(isCopy);    
    if (isKitCreating && isIqsSscDeKit){
      history.replace(`/edit-zmcc-kit/${kitInformationId}`);
    } else{
      if (success) {
        history.replace(`/kit_details/${kitInformationId}`);
      } 
    }
  };

  const cancelCreateKit = () => {
    history.replace('/kits');
  };

  const openInRefurbishmentPopup = () => {
    $('#inRefurbishmentStatus').modal('show');
  };
  
  const count =0;

  const generateTabs = useCallback(() => [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: () => (
        <InformationTab
          cancelCreateKit={cancelCreateKit}
          saveKit={saveKit}
          handleForm={handleForm}
          updateForm={updateForm}
          rooms={rooms}
          zmccUsers={zmccUsers}
          businessUnitsData={businessUnitsData}
        />
      ),
    },
    {
      id: 2,
      tabTitle: 'components',
      className: '',
      render: () => (
        <Components
          history={history}
          kitDetails={kitDetails}
          saveKit={saveKit}
          formValue={{ furtherImportantComponentForm, salesComponentForm }}
          cancelCreateKit={cancelCreateKit}
          handleForm={handleForm}
          isOptional={true}
        />
      ),
    },
    {
      id: 3,
      tabTitle: 'accessories',
      className: '',
      render: () => (
        <Accessories
          history={history}
          kitDetails={kitDetails}
          saveKit={saveKit}
          formValue={{ freeAccessoriesForm }}
          cancelCreateKit={cancelCreateKit}
          handleForm={handleForm}
        />
      ),
    },
    {
      id: 4,
      tabTitle: 'attachments',
      className: '',
      render: () => 
        <Attachments
          history={history}
          kitDetails={kitDetails}
          saveKit={saveKit}
          formValue={{ kitAttachments, kitAttachmentsToDelete }}
          cancelCreateKit={cancelCreateKit}
          kitInformationId={kitInformationId}
          handleForm={updateForm}
          hasVideo={true}
          newVersionUi={true}
          uploaderStyle={"kit-attachments-bordered"}
          enhancedUi={true}
          sectionWrapper={true}
        />
    },
  ], [cancelCreateKit, freeAccessoriesForm, furtherImportantComponentForm, handleForm, history, kitAttachments, kitAttachmentsToDelete, kitDetails, kitInformationId, salesComponentForm, saveKit, updateForm]);

  const generateTabsForVrDemoKit = useCallback(() => [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: () => (
        <InformationTab
          cancelCreateKit={cancelCreateKit}
          saveKit={saveKit}
          handleForm={handleForm}
          updateForm={updateForm}
          rooms={rooms}
          zmccUsers={zmccUsers}
        />
      ),
    },
    {
      id: 2,
      tabTitle: 'components',
      className: '',
      render: () => (
        <Components
          history={history}
          kitDetails={kitDetails}
          saveKit={saveKit}
          formValue={{ furtherImportantComponentForm, salesComponentForm }}
          cancelCreateKit={cancelCreateKit}
          handleForm={handleForm}
          isOptional={true}
        />
      ),
    },
    {
      id: 3,
      tabTitle: 'accessories',
      className: '',
      render: () => (
        <Accessories
          history={history}
          kitDetails={kitDetails}
          saveKit={saveKit}
          formValue={{ freeAccessoriesForm }}
          cancelCreateKit={cancelCreateKit}
          handleForm={handleForm}
        />
      ),
    },
    {
        id: 4,
        tabTitle: 'vrDetails',
        className: '',
        render: () => (
          <VRDetailsForm 
            formValue={{vrDetalisForm}}
            kitDetail={kitDetails}
            history={history} 
            saveKit={saveKit}
            cancelCreateKit={cancelCreateKit}
            handleForm={handleForm}
          />
        ),
      },
    {
      id: 5,
      tabTitle: 'attachments',
      className: '',
      render: () => (
        <Attachments
          history={history}
          kitDetails={kitDetails}
          saveKit={saveKit}
          formValue={{ kitAttachments, kitAttachmentsToDelete }}
          cancelCreateKit={cancelCreateKit}
          kitInformationId={kitInformationId}
          handleForm={updateForm}
          hasVideo={true}
        />
      ),
    },
  ], [cancelCreateKit, freeAccessoriesForm, furtherImportantComponentForm, handleForm, history, kitAttachments, kitAttachmentsToDelete, kitDetails, kitInformationId, salesComponentForm, saveKit, updateForm]);


  const generateTabsForIQSKit = useCallback(() => {
    const tabs = [
      {
        id: 1,
        tabTitle: 'information',
        className: '',
        render: () => (
          <InformationTab
            cancelCreateKit={cancelCreateKit}
            saveKit={saveKit}
            handleForm={handleForm}
            updateForm={updateForm}
            rooms={rooms}
            zmccUsers={zmccUsers}
            businessUnitsData={businessUnitsData}
            fetchUserList={fetchUserList}
          />
        ),
      },
      {
        id: 2,
        tabTitle: 'components',
        className: '',
        render: () => (
          <NewCreateKitComponents 
            history={history}
            kitDetails={kitDetails && kitDetails}
            saveKit={saveKit}
            formValue={{ newComponentsForm }}
            cancelCreateKit={cancelCreateKit}
            handleForm={handleForm}
            isOptional={true}
            addUpdateNewComponent={addUpdateNewComponent}
            fetchNewComponentsList={fetchNewComponentsList}
            newComponentsList={newComponentsList}
            updateForm={updateForm}
            toggleActionMessage={toggleActionMessage}
            deleteNewComponent={deleteNewComponent}
          /> 
        ),
      },
      {
        id: 3,
        tabTitle: 'messages',
        className: '',
        count,
        render: isActive => (
          <KitMessagesTab 
            createKitMessage={createKitMessage}
            kitDetails={kitDetails && kitDetails}
            fetchKitMessagesList={fetchKitMessagesList}
            kitMessagesList={kitMessagesList}
          />
        ),
      },
      {
        id: 4,
        tabTitle: 'attachments',
        className: '',
        render: () => (
          <IqsAttachment 
            isActive={true} 
            getIqsSscAttachmentList={getIqsSscAttachmentList}
            canEditStock={true}
            history={history} 
            kitResourceAttachmentCategoriesList={kitResourceAttachmentCategoriesList}
            enableIOOptions={true}
            kitDetails={kitDetails && kitDetails}
            kitNewAttachments={kitNewAttachments}
            getKitAttachmentList={getKitAttachmentList}
            changeLoader={changeLoader}
            deleteKitAttachment={deleteKitAttachment}
          /> 
        ),
      }
    ];
  
    return tabs;
  }, [cancelCreateKit, handleForm, history, kitDetails, newComponentsForm, saveKit, updateForm, count, kitMessagesList, kitNewAttachments, kitResourceAttachmentCategoriesList]);
  
  
  
  const title = getTitle();
  const tabs =  kitDetails && kitDetails.systemClass && kitDetails.systemClass.systemClassName && kitDetails.systemClass.systemClassName.toLowerCase() === 'VR Demo Kit'.toLowerCase() ? generateTabsForVrDemoKit() : buDesignation === IQS_SSC_DE_DESIGNATION ? generateTabsForIQSKit() : generateTabs();
  
  const onMaxFileReached = (maxFileReached, multipleUploads) => {
    if(maxFileReached){
      setMaxFilesError(strings.maxFilesErrorMessageZmcc.replace("{number}", 6))
    }
  }

  return {
    title,
    tabs,
    addKitImageFile,
    removeKitImageFile,
    handleForm,
    getTitle,
    saveKit,
    cancelCreateKit,
    onMaxFileReached,
    maxFilesError,
    gernalInformationModelEX
  };
}
