import Validators from "library/utilities/Validators";

export const newComponentsModel = [
  {
    label: 'quantity',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'componentNumber',
    validators: [{check: Validators.number, message: 'invalidNumberError'}, {check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-3',
  },
  {
    label: 'name',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'componentName',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-3',
  },
  {
    label: 'materialNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'materialNumber',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  },
  {
    label: 'serialNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'serialNumber',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  }
];