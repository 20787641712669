import React, { useCallback, useEffect, useState } from 'react';
import { uploadAttachmentModel } from './UploadAttachmentPopup.constants';
import { validateForm } from 'library/utilities/ValidateForm.util';
import * as $ from 'jquery';
import strings from 'resources/locales/Translate';
import Validators from 'library/utilities/Validators';

export const useUploadAttachmentPopup = ({ id, user, attachmentCategory, uploadIqsAttachment, uploadNewKitAttachment, stockId, enableIOOptions, kitId, kitResourceAttachmentCategoriesList }) => {
  const [files, setFiles] = useState(null);
  const [uploadFormData, setUploadFormData] = useState({ formData: {} });
  const [fileType, setFileType] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setPredefinedData();
  }, []);

  useEffect(() => {
    if (files?.length > 0) {
      const lastDotIndex = files[0].name.lastIndexOf('.');
      let nameWithoutExtension = files[0].name.substring(0, lastDotIndex);

      setUploadFormData(prevState => ({
        formData: {
          ...prevState.formData,
          fileName: nameWithoutExtension,
        },
      }));
      setFileType(files[0].name.substring(lastDotIndex + 1));
    }
  }, [files]);

  const setPredefinedData = () => {
    setUploadFormData({
      formData: {
        uploadedBy: `${user.firstName} ${user.lastName}`,
        uploadatedAt: new Date(),
      },
    });
  };
  const getModel = () => {
    const model = [...uploadAttachmentModel];
    model[1].options = enableIOOptions ? kitResourceAttachmentCategoriesList : attachmentCategory;

    if (enableIOOptions){
      model[1].styleClass = 'col-6';
      model.splice(2,0,{
        label: 'ioOptions',
        value: '',
        type: 'enhanced-circle-RadioButton',
        placeholder: '',
        field: 'evaluation',
        validators: [],
        required: false,
        styleClass: 'col-6',
        options: [
          {
            id: 'YES',
            value: 'io',          
          },
          {
            id: 'NO',
            value: 'nio',
          },
        ],
      })
    }

    return model;
  };

  const addFile = file => {
    setFiles(file);
    setError('');
  };

  const handleForm = ({ values, field }) => {
    setUploadFormData({
      ...uploadFormData,
      formData: {
        ...uploadFormData.formData,
        ...values,
      },
    });
    setError('');
  };
  const onFileDelete = () => {
    setFiles(null);
    setUploadFormData(prevState => ({
      formData: {
        ...prevState.formData,
        fileName: '',
      },
    }));
  };

  const onSaveClick = async () => {
    const validForm = validateForm({
      form: uploadFormData,
      model: getModel(),
    });

    if (!validForm.isFormValid) {
      return setUploadFormData(validForm);
    }
    if (files === null) {
      setError('Please upload attachment');
      return;
    }
    const { fileName, category, comment, evaluation} = validForm.formData;
    
    const dataToSend = {
      fileName: `${fileName}.${fileType}`,
      category: category,
      comment: comment,
      attachment: files,
      stockId: stockId,
      kitId: kitId,
      evaluation: evaluation
    }
    setLoading(true);
  const { success, error } =  (enableIOOptions && kitId) ? await uploadNewKitAttachment(dataToSend) :await uploadIqsAttachment(dataToSend);
  setLoading(false);
    if (!success) {
      if (error?.response?.data?.status === 422) {
        setError(error.response.data.message);
      }else{
        setError(strings.somethingWentWrongMessage);
      }
    }else{
      onCloseClick();
      setFiles(null)
    }
  }

  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
    setPredefinedData();
    setError('');
    setFiles(null);
  }

  return {
    addFile,
    getModel,
    uploadFormData,
    handleForm,
    files,
    onFileDelete,
    onSaveClick,
    onCloseClick,
    loading,
    error,
  };
};
