import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { IoIosPrint } from 'react-icons/io';
import strings from 'resources/locales/Translate';

import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/Breadcrumbs';
import Icon from 'library/common/commonComponents/Icon/Icon';
import Button from 'library/common/commonComponents/Button/Button';
import ImageGallery from 'library/common/commonComponents/ImageGallery/ImageGallery';
import Print from 'library/common/commonComponents/KitPrint';
import "./kitDetailsMainSection.styles.scss";

const KitDetailsMainSection = ({
  breadcrumbs,
  checkKitImage,
  desplayedImages,
  images,
  kitDetails,
  isLabsLocationPartner,
  isZMCCKitType,
  isIqsSscDeKitType,
  description,
  isDescriptionLengthMoreThanLimit,
  isFullDescriptionShown,
  isErpActivated,
  alreadyAddedToCart,
  isBookingAndReserveAllowed,
  bookNowHandler,
  isStatusOk,
  isTwentyFourReservationAllowed,
  isIncompleteMaintained,
  isInvisible,
  isNotAvailable,
  isOk,
  hasOnlyDustUserRole,
  goToCopyKit,
  isEditable,
  isDeletableAndCanCopied,
  getPriceBasedonLanguage,
  toggleFullDescription,
  language,
  getErrorMessageForIncompleteMaintainedKit,
}) => {
  const printComponentRef = useRef(null);

  return (
    <>
      <div className='container-fluid mb-3'>
        <div className='d-flex'>
          <Breadcrumbs data={breadcrumbs} />
        </div>
        <div className='mainSection'>
          <div style={
            {marginLeft : `${checkKitImage ? '-15px' : ''}`, width:'35%'}}>
            <div className='mb-3'>
              {checkKitImage ? (
                <ImageGallery images={desplayedImages} />
              ) : (
                <img alt='' src={images.imagePlaceholder} className='dummy-image' />
              )}
            </div>
            <div className='d-flex justify-content-start' style={{marginLeft: checkKitImage ? '81px' : ''}}>
              {!hasOnlyDustUserRole && (
                <div className='button-blue' data-test='copyKit-button' onClick={goToCopyKit}>
                  <Icon name='copy' width={24} height={24} fill='#0088d0' />
                  <div className='tooltiptext'>
                    Copy
                    <i />
                  </div>
                </div>
              )}
              {isEditable && (
                <div className='button-blue'>
                  <NavLink
                    to={{
                      pathname: isLabsLocationPartner
                        ? `/edit-llkit/${kitDetails.kitInformationId}`
                        : (isZMCCKitType || isIqsSscDeKitType)
                        ? `/edit-zmcc-kit/${kitDetails.kitInformationId}`
                        : `/edit-kit/${kitDetails.kitInformationId}`,
                      state: { edit: true },
                    }}
                  >
                    <Icon name='edit' width={24} height={24} fill='#0088d0' />
                  </NavLink>
                  <div className='tooltiptext'>
                    {strings.edit}
                    <i />
                  </div>
                </div>
              )}
              {!hasOnlyDustUserRole && (
                <ReactToPrint
                  trigger={() => (
                    <div className='button-blue'>
                      <IoIosPrint size={24} color='#0088d0' />
                      <div className='tooltiptext'>
                        {strings.print}
                        <i />
                      </div>
                    </div>
                  )}
                  content={() => printComponentRef.current}
                />
              )}
              {!kitDetails.isTransactionAssociated && isDeletableAndCanCopied && (
                <div
                  className='button-red'
                  data-toggle='modal'
                  data-target={`#confirmKitDeleteModal${kitDetails.kitInformationId}`}
                >
                  <Icon name='delete' width={24} height={24} fill='#ff1a00' />
                  <div className='tooltiptext'>
                    {strings.delete}
                    <i />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div style={{ width:'55%'}}>
            <h2 className='title'>{kitDetails.kitName}</h2>
            <div className='kit-number-text'>{`${strings.kitNumber}: ${kitDetails.kitInformationId}`}</div>
            {!isLabsLocationPartner && !isZMCCKitType && (
              <div className='kit-number-text'>{`${strings.ibaseText}: ${
                kitDetails.ibase ? kitDetails.ibase : '-'
              }`}</div>
            )}
            <div className='subtitle'>{strings.descriptionText}</div>
            <div className='description-container mb-3'>{description}</div>
            <div className='full-description' onClick={toggleFullDescription(isDescriptionLengthMoreThanLimit)}>
              <Icon
                name={ isFullDescriptionShown   ? 'hideFilter' : 'visibility'}
                width={24}
                height={24}
                fill='#0088d0'
                className='mr-2'
                viewBox={isFullDescriptionShown  ? '0 0 20 20' : '0 0 40 40'}
              />
              <span
                style={{ color: '#0088d0' }}
              >
                {isFullDescriptionShown ? strings.hideDescription :strings.fullDescription}
              </span>
            </div>
            {!isLabsLocationPartner && !isZMCCKitType && (
              <div>
                <div className='list-price'>{strings.systemValue}</div>
                <div className='price'>{getPriceBasedonLanguage(kitDetails.systemValue, language)}</div>
              </div>
            )}
            {isErpActivated && (
              <div>
                <div className='list-price'>{strings.netValue}</div>
                <div className='price'>
                  {getPriceBasedonLanguage(kitDetails.netValue, language?.language)}
                </div>
              </div>
            )}
            {!isLabsLocationPartner && !isZMCCKitType && kitDetails.kitStatuses && !isStatusOk && (
              <div className='error-block mt-3 mb-3'>
                {isIncompleteMaintained && (
                  <p className='mb-1'>{getErrorMessageForIncompleteMaintainedKit(kitDetails)}</p>
                )}
                {isInvisible && <p className='mb-1'>{strings.hideKitIsActivated}</p>}
                {isNotAvailable && <p className='mb-1'>{strings.availabilityPeriodIsExpired}</p>}
                {!isOk && <p className='dummyClassForSpec mb-1'>{strings.statusIsNotOk}</p>}
              </div>
            )}
            {isStatusOk && !isTwentyFourReservationAllowed && (
              <div className='error-block mt-3 mb-3'>{strings.twentyFourHourReservationNotAvailable}</div>
            )}
          </div>

          <div className='' style={{ width:'25%'}}>
            <div className='row no-gutters align-items-center justify-content-end mb-3'>
              {!alreadyAddedToCart ? (
                <>
                  {isBookingAndReserveAllowed && (
                    <div className='col-lg-auto col-12 mr-2 p-2'>
                      <Button
                        bgFill={true}
                        value={strings.bookNow}
                        onClick={() => bookNowHandler(kitDetails)}
                        disabled={!isStatusOk}
                        styleClass='kit-create-buttons'
                      />
                    </div>
                  )}
                  {!isLabsLocationPartner && !isZMCCKitType && isBookingAndReserveAllowed && (
                    <div className='col-lg-auto col-12 mt-2 mt-lg-0 mr-2'>
                      <Button
                        bgFill={false}
                        value={strings.reserve}
                        disabled={!isStatusOk || !isTwentyFourReservationAllowed}
                        onClick={() => bookNowHandler(kitDetails)}
                        styleClass='kit-create-buttons'
                      />
                    </div>
                  )}
                </>
              ) : (
                <span className='mr-2'>{strings.addedToCart}</span>
              )}
            </div>
            <div className='d-flex justify-content-end p-3'>
              {kitDetails.qrCode && <img src={kitDetails.qrCode} alt='' className='grCode-image' />}
            </div>
          </div>
        </div>
      </div>
      <div className='print-hidden'>
        <Print ref={printComponentRef} kitDetails={kitDetails} />
      </div>
    </>
  );
};

export default KitDetailsMainSection;