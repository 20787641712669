import Validators from 'library/utilities/Validators';
import strings from 'resources/locales/Translate';

export const informationFormModel = [
  {
    label: 'businessUnit',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'businessUnit',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
    disabled: false,
  },
  {
    label: 'zmccFullForm',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'stockInformation',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: true,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'locationName', separator: '' }],
    optionsArrayKey: 'stockInformation',
    titleDisplay: [
      { key: 'shortName', separator: ' ' },
      { key: 'name', separator: '' },
    ],
  },
  {
    label: 'systemClassText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemClass',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'systemClassId',
    displayValue: [{ key: 'systemClassName', separator: '' }],
    disabled: false,
  },
  {
    label: 'systemMainComponent',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemMainComponent',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'systemComponentId',
    displayValue: [{ key: 'systemMainComponentName', separator: '' }],
  },
  {
    label: 'systemCategory',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'zmccSystemCategory',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
  },
  {
    label: 'serialNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'serialNumber',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  },
  {
    label: 'ibaseText',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'ibase',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  },
  {
    label: 'dateOfManufacture',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'dateOfManufacture',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    dateFormat: 'MM/YYYY',
  },
  {
    label: 'availableFrom',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'availabilityFrom',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
  },
  {
    label: 'availableTo',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'availabilityTo',
    styleClass: 'col-sm-3',
  },
  {
    label: 'inputRoomLabel',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'room',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'warehouseRoomId',
    displayValue: [{ key: 'roomName', separator: '' }],
  },
  {
    label: 'owner',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'owner',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'userId',
    displayValue: [{ key: 'lastName', separator: ', ' },{ key: 'firstName', separator: '' }],
  },
  {
    label: 'isMobileOrExternal',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'isMobileOrExternal',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    options: [
      {
        id: 'Yes',
        value: 'yes',
      },
      {
        id: 'No',
        value: 'no',
      },
    ],
    displayValue: [{ key: 'id', separator: '' }],
  },
];

export const kitConditionFormModel = [
  {
    label: 'conditionText',
    value: '',
    type: 'dropdown',
    field: 'kitCondition',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'kitStatusId',
    displayValue: [{ key: 'translatedStatus', separator: '' }],
  },
  {
    label: 'function',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'comment',
    validators: [],
    required: false,
    styleClass: 'col-sm-9',
  },
];


export const informationFormIQSModel = [
  {
    label: 'businessUnit',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'businessUnit',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
    disabled: false,
  },
  {
    label: 'country',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'resourceCountry',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
    disabled: false,
  },
  {
    label: 'location',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'stockInformation',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: true,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'locationName', separator: '' }],
    optionsArrayKey: 'stockInformation',
    titleDisplay: [
      { key: 'shortName', separator: ' ' },
      { key: 'name', separator: '' },
    ],
  },
  {
    label: 'inputRoomLabel',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'room',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'warehouseRoomId',
    displayValue: [{ key: 'roomName', separator: '' }],
    disabled: false,
  },
  {
    label: 'responsiblePerson',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'owner',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'userId',
    displayValue: [{ key: 'lastName', separator: ', ' },{ key: 'firstName', separator: '' }],
  },
  {
    label: 'scheduleType',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'resourceType',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'ibaseText',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'ibase',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    newRefreshIcon : true,
  },
  {
    label: 'isMobileOrExternal',
    value: '',
    type: 'enhanced-circle-RadioButton',
    placeholder: 'pleaseSelect',
    field: 'isMobileOrExternal',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    options: [
      {
        id: 'Yes',
        value: 'yes',
      },
      {
        id: 'No',
        value: 'no',
      },
    ],
    displayValue: [{ key: 'id', separator: '' }],
  },
  {
    label: 'marketingMaterialText',
    selected: false,
    type: 'enhanced-checkbox',
    field: 'marketingMaterial',
    styleClass: 'col-sm-3',
    newuiVersionStyle: true,
    labelTopPosition: true
  },
  {
    label: 'supportingAsset',
    selected: false,
    type: 'enhanced-checkbox',
    field: 'supportingAsset',
    styleClass: 'col-sm-3',
    newuiVersionStyle: true,
    labelTopPosition: true
  },
  {
    label: 'fixedAsset',
    selected: false,
    type: 'enhanced-checkbox',
    field: 'fixedAsset',
    styleClass: 'col-sm-3',
    newuiVersionStyle: true,
    labelTopPosition: true
  },
];

export const iqsIdentifierModel = [
    {
    label: 'storageLocation',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'storageLocation',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  },
  {
    label: 'sapPlant',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'sapPlant',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  },
  {
    label: 'sapStorageLocation',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'sapStorageLocation',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  },
  {
    label: 'materialNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'materialNumber',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  },
  {
    label: 'serialNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'serialNumber',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
  },
  {
    label: 'inventoryNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'inventoryNumber',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  },
  {
    label: 'dateOfManufacture',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'dateOfManufacture',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
  },
];

export const iqsStatusFormModel = [
  {
    label: 'lastMaintenance',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'lastMaintenanceDate',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  },
  {
    label: 'maintenanceIntervalInMonths',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'maintenanceInterval',
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    required: true,
    styleClass: 'col-sm-3',
  },
  {
    label: 'availableFrom',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'availabilityFrom',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
  },
  {
    label: 'availableTo',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'availabilityTo',
    styleClass: 'col-sm-3',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
  },
  {
    label: 'status',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'resourceStatus',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'purpose',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'purpose',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
  },
];

export const iqsLoanInformationModel = [
  {
    label: 'delivery',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'deliveryDays',
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    validateOnBlur: true,
    required: false,
    styleClass: 'col-sm-3',
    inputGroupText: 'days',
    isAppend: true,
    neweVersionStyle: true,
  },
  {
    label: 'returnDelivery',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'returnDeliveryDays',
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    validateOnBlur: true,
    required: false,
    styleClass: 'col-sm-3',
    inputGroupText: 'days',
    isAppend: true,
    neweVersionStyle: true,
  },
  {
    label: 'reconditioning',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'reconditioningDeliveryDays',
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    validateOnBlur: false,
    required: false,
    styleClass: 'col-sm-3',
    inputGroupText: 'days',
    isAppend: true,
    neweVersionStyle: true,
  },
  {
    label: 'maxLoanDuration',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'maxLoanDuration',
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    validateOnBlur: false,
    required: false,
    styleClass: 'col-sm-3',
    inputGroupText: 'days',
    isAppend: true,
    neweVersionStyle: true,
  },
  {
    label: 'iqsPackageDimensions',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'packageDimensions',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  },
];


export const resourceTypeSystemModelValues = [
  {
  label: 'category',
  value: '',
  type: 'dropdown',
  placeholder: 'pleaseSelect',
  field: 'productHierarchy',
  validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
  required: true,
  styleClass: 'col-sm-3',
  options: [],
  hasSection: false,
  multiSelect: false,
  filter: true,
  idKey: 'id',
  displayValue: [{ key: 'name', separator: '' }],
},
{
  label: 'iqsSystemClassLabel',
  value: '',
  type: 'dropdown',
  placeholder: 'pleaseSelect',
  field: 'systemClass',
  validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
  required: true,
  styleClass: 'col-sm-3',
  options: [],
  hasSection: false,
  multiSelect: false,
  filter: true,
  idKey: 'systemClassId',
  displayValue: [{ key: 'systemClassName', separator: '' }],
  disabled: false,
},
{
  label: 'iqsSystemMainComppoenetLabel',
  value: '',
  type: 'dropdown',
  placeholder: 'pleaseSelect',
  field: 'systemMainComponent',
  validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
  required: true,
  styleClass: 'col-sm-3',
  options: [],
  hasSection: false,
  multiSelect: false,
  filter: true,
  idKey: 'systemComponentId',
  displayValue: [{ key: 'systemMainComponentName', separator: '' }],
}];

export const resourceTypeNonSystemModelValues = [{
  label: 'category',
  value: '',
  type: 'text',
  placeholder: '',
  field: 'resourceCategory',
  validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
  required: true,
  styleClass: 'col-sm-3',
}]
