import { connect } from 'react-redux';

import InformationTab from './InformationTab.component';
import { 
  fetchSystemMainComponent, 
  fetchRoomsListForWarehouse, 
  fetchResourceTypesDropdownValues, 
  fetchProductHeirarchyDropdownValues,
  fetchResourceStatusesDropdownValues
} from '../../CreateZmccKit.actions';
import { getSystemCategoriesList } from 'modules/ZmccRequest/SEMBooking/SemBooking.actions';

const mapStateToProps = ({ authReducer, createZmccKitReducer, languageReducer, masterDataReducer, createSEMFormReducer }) => ({
  informationForm: createZmccKitReducer.informationForm,
  kitStatusForm: createZmccKitReducer.kitStatusForm,
  stocks: createZmccKitReducer.stocks,
  systemClasses: createZmccKitReducer.systemClasses,
  systemMainComponent: createZmccKitReducer.systemMainComponent,
  conditions: createZmccKitReducer.conditions,
  activeUntil: createZmccKitReducer.activeUntil,
  language: languageReducer,
  user: authReducer.user,
  kitDetails: createZmccKitReducer.kitDetails,
  resourceTypeValues: createZmccKitReducer.resourceTypeValues,
  productHierarchyValues: createZmccKitReducer.productHierarchyValues,
  masterData: masterDataReducer,
  iqsIdentifierForm: createZmccKitReducer.iqsIdentifierForm,
  iqsStatusForm: createZmccKitReducer.iqsStatusForm,
  resourceStatusValues: createZmccKitReducer.resourceStatusValues,
  iqsLoanInformationForm: createZmccKitReducer.iqsLoanInformationForm,
  systemCategoriesList: createSEMFormReducer.systemCategoriesList
});

export default connect(mapStateToProps, {
  fetchSystemMainComponent,
  fetchRoomsListForWarehouse,
  fetchResourceTypesDropdownValues,
  fetchProductHeirarchyDropdownValues,
  fetchResourceStatusesDropdownValues,
  getSystemCategoriesList
})(InformationTab);
