import { useEffect, useCallback } from 'react';
import get from 'lodash/get';

import { refurbishmentPeriodModel } from '../../../CreateKit/Tabs/Information/Information.constants';
import { informationFormModel, kitConditionFormModel, informationFormIQSModel , iqsIdentifierModel, iqsStatusFormModel, iqsLoanInformationModel, resourceTypeNonSystemModelValues, resourceTypeSystemModelValues} from './InformationTab.constants';
import { IQS_SSC_DE_DESIGNATION, RESOURCE_TYPE_SYSTEM } from 'library/utilities/constants';

export const useInformationTab = props => {
  const {
    informationForm,
    kitStatusForm,
    stocks,
    systemClasses,
    systemMainComponent,
    fetchSystemMainComponent,
    conditions,
    activeUntil,
    kitDetails,
    fetchActiveUnitDate,
    masterData,
    rooms,
    fetchRoomsListForWarehouse,
    zmccUsers,
    businessUnitsData,
    resourceTypeValues,
    fetchResourceTypesDropdownValues,
    fetchProductHeirarchyDropdownValues,
    productHierarchyValues,
    fetchResourceStatusesDropdownValues,
    resourceStatusValues,
    language,
    fetchUserList,
    getSystemCategoriesList,
    systemCategoriesList
  } = props;

  useEffect(() => {
      getSystemCategoriesList();
  }, [ getSystemCategoriesList]);

  useEffect(() => {
    if (informationForm.formData.systemClass) {
      fetchSystemMainComponent(informationForm.formData.systemClass);
    }
  }, [informationForm.formData.systemClass, fetchSystemMainComponent]);

  useEffect(() => {
    const buDesignation = informationForm.formData?.businessUnitFullValue?.designation;
    if (buDesignation === IQS_SSC_DE_DESIGNATION) {
      fetchResourceTypesDropdownValues();
      fetchProductHeirarchyDropdownValues(informationForm.formData?.businessUnitFullValue?.businessUnitGroup);
      fetchResourceStatusesDropdownValues();
      if (!masterData.usersList || !masterData.usersList.length) {
        fetchUserList(); 
      }
    }
  }, [informationForm.formData.businessUnit, fetchResourceTypesDropdownValues, fetchProductHeirarchyDropdownValues, fetchResourceStatusesDropdownValues, language,fetchUserList]);

  useEffect(() => {
    if (informationForm.formData.stockInformation) {
      fetchRoomsListForWarehouse(informationForm.formData.stockInformation);
    }
  }, [informationForm.formData.stockInformation, fetchRoomsListForWarehouse]);

  const getInformationModel = useCallback(() => {
    const buDesignation = informationForm.formData?.businessUnitFullValue?.designation;
    const isResourceTypeSYSTEM =  informationForm.formData?.resourceType  === RESOURCE_TYPE_SYSTEM;
    let model = [...informationFormModel];
    if (buDesignation === IQS_SSC_DE_DESIGNATION) {
      model = [...informationFormIQSModel];
      model[0].options = businessUnitsData;
      model[1].options = masterData?.countries;
      model[2].options = stocks;
      model[3].options = rooms;
      model[4].options = masterData.usersList;
      model[5].options = resourceTypeValues;
      if (isResourceTypeSYSTEM){
        model.splice(6, 0, ...resourceTypeSystemModelValues);
        model[6].options = productHierarchyValues;
        model[7].options = systemClasses;
        model[8].options = systemMainComponent;
      } else{
        model.splice(6, 0,  ...resourceTypeNonSystemModelValues);
      }


    } else{
      model = [...informationFormModel];
      const date = activeUntil ? new Date(activeUntil) : null;
      model[1].options = stocks;
      model[0].options = businessUnitsData;
      model[2].options = systemClasses;
      model[3].options = systemMainComponent;
      model[4].options = systemCategoriesList;
      model[9].maxDate = date;
      model[9].minDate = informationForm.formData.availabilityFrom;
      model[8].maxDate = informationForm.formData.availabilityTo || date;
      model[10].options = rooms;
      model[11].options = zmccUsers;
    }


    return model;
  }, [informationForm, activeUntil, stocks, systemClasses, systemMainComponent, rooms, zmccUsers, businessUnitsData, resourceTypeValues, productHierarchyValues, masterData, systemCategoriesList]);

  const getKitConditionsModel = useCallback(() => {
    const model = [...kitConditionFormModel];

    if (conditions.length) {
      const unusedСonditionIds = [6, 10, 11, 12, 1, 3, 7, 9];
      const newOptions = conditions.filter(condition => !unusedСonditionIds.includes(condition.kitStatusId));
      const okCondition = conditions.find(condition => condition.kitStatusId === 6);

      newOptions.unshift(okCondition);
      model[0].options = newOptions;
    }

    if (get(kitStatusForm, 'formData.kitCondition') === 5) {
      model.splice(1, 0, ...refurbishmentPeriodModel);
      model[1].minDate = new Date();
      model[2].minDate = kitStatusForm.formData.refurbishmentFrom;
    }

    return model;
  }, [conditions, kitStatusForm]);

  const getIqsStatusModel = useCallback(() => {
    const model = [...iqsStatusFormModel];
    model[4].options = resourceStatusValues;
    return model;
  }, [resourceStatusValues])

  const informationModel = getInformationModel();
  const kitConditionModel = getKitConditionsModel();
  const iqsStatusModel = getIqsStatusModel();

  return {
    informationModel,
    kitConditionModel,
    getInformationModel,
    getKitConditionsModel,
    iqsIdentifierModel,
    iqsStatusModel,
    iqsLoanInformationModel
  };
};
