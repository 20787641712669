import Validators from 'library/utilities/Validators';

export const uploadAttachmentModel = [
  {
    label: 'scheduleInputTitle',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'fileName',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'category',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'category',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'uploadedDate',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'uploadatedAt',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    dateFormat: 'DD.MM.YYYY',
    disabled: true,
  },
  {
    label: 'uploadedBy',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'uploadedBy',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: true,
  },
  {
    label: 'comment',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'comment',
    validators: [],
    required: false,
    styleClass: 'col-12',
  },
];
