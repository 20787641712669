import Validators from 'library/utilities/Validators';

export const UPDATE_ZMCC_KIT_FORM = 'UPDATE_ZMCC_KIT_FORM';
export const FETCH_WAREHOUSES_FOR_ZMCC_BU = 'FETCH_WAREHOUSES_FOR_ZMCC_BU';
export const FETCH_SYSTEM_CLASSES_ZMCC_BU= 'FETCH_SYSTEM_CLASSES_ZMCC_BU';
export const FETCH_SYSTEM_MAIN_COMPONENT_ZMCC_BU = 'FETCH_SYSTEM_MAIN_COMPONENT_ZMCC_BU';
export const FETCH_WAREHOUSE_ROOMS_ZMCC_BU = 'FETCH_WAREHOUSE_ROOMS_ZMCC_BU';
export const FETCH_WAREHOUSE_USERS_ZMCC_BU = 'FETCH_WAREHOUSE_USERS_ZMCC_BU';
export const FETCH_ZMCC_BU_CONDITIONS = 'FETCH_ZMCC_BU_CONDITIONS';
export const FETCH_ZMCC_KIT_DETAILS = 'FETCH_ZMCC_KIT_DETAILS';
export const RESET_ZMCC_BU_KIT_FORM = 'RESET_ZMCC_BU_KIT_FORM';
export const FETCH_RESOURCE_FUNCTION_VALUES = 'FETCH_RESOURCE_FUNCTION_VALUES';
export const FETCH_RESOURSE_TYPE_VALUES = 'FETCH_RESOURSE_TYPE_VALUES';
export const FETCH_PRODUCT_HEIRARCHY_VALUES = 'FETCH_PRODUCT_HEIRARCHY_VALUES';
export const FETCH_RESOURCE_STATUS_VALUES = 'FETCH_RESOURCE_STATUS_VALUES';
export const FETCH_NEW_COMPONENTS_LIST = 'FETCH_NEW_COMPONENTS_LIST';
export const FETCH_KIT_MESSAGES_LIST = 'FETCH_KIT_MESSAGES_LIST';
export const FETCH_KIT_RESOURCE_ATTACHEMNT_CATEGORIES  = 'FETCH_KIT_RESOURCE_ATTACHEMNT_CATEGORIES';
export const KIT_NEW_ATTACHMENT_LIST  = 'KIT_NEW_ATTACHMENT_LIST';

export const generalInformationZMCCModel = [
  {
    label: 'kitInformationId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'kitInformationId',
    validators: [],
    required: true,
    styleClass: 'col-sm-6',
    disabled: true
  },
  {
    label: 'kitName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'kitName',

    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-6'
  },
  {
    label: 'descriptionText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'description',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-12'
  },
];
  

export const generalInformationIQSModel = [
  {
    label: 'resourceId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'kitInformationId',
    validators: [],
    required: true,
    styleClass: 'col-sm-4',
    disabled: true
  },
  {
    label: 'resourceName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'kitName',

    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-4'
  },
  {
    label: 'function',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'resourceFunction',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: '' }],
    disabled: false,
  },
  {
    label: 'descriptionText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'description',
    validators: [],
    required: false,
    styleClass: 'col-sm-12'
  },
];
  