import React from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';
import { scrollToTop } from 'library/utilities/scrollActions';
import ExpandableTable from 'library/common/commonComponents/Table/AppExpandableTable';
import Button from 'library/common/commonComponents/Button/Button';

import {
  newComponentsModel,
} from './NewComponents.constants';
import './NewComponents.style.scss';
import Section from 'library/common/commonComponents/Section/Section';
import { useNewComponents } from './NewComponents.hook';

const TAB_ID = 2;

export const NewCreateKitComponents = props => {
  const { formValue, saveKit, cancelCreateKit, handleForm, isOptional, toggleActionMessage, kitDetails, addUpdateNewComponent, newComponentsList, fetchNewComponentsList,updateForm,deleteNewComponent
} = props;
  const kitId = kitDetails?.kitInformationId;
  const canEditKit = true;

  const { getCols, newComponentsUpdatedList , onAddComponentClick} = useNewComponents(
    {
      kitId, 
      canEditKit,
      addUpdateNewComponent,
      newComponentsList,
      fetchNewComponentsList,
      formValue,
      updateForm,
      deleteNewComponent
    });


  const newComponentsListLength = newComponentsUpdatedList.length > 0;
  
  const handleSaveKit = () => {
    if (newComponentsListLength) {
      saveKit(TAB_ID);
    } else {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'pleaseAddAtlestOneComponents');
    }
  };

  return (
    <div className='information-container mb-5'>
      <div className='container-fluid form-container'>
          <div className='justify-content-between align-items-center bg-filters'>
            <div className='col-sm-10'>
              <FormComponent
                model={newComponentsModel}
                formName='newComponentsForm'
                formValue={formValue.newComponentsForm.formData}
                onChange={handleForm}
              />
            </div>
            <div className='col-sm-2'>
              <Button
                  bgFill={false}
                  iconName='plus'
                  onClick={() => onAddComponentClick(formValue.newComponentsForm.formData)}
                  value={strings.addComponent}
                  styleClass={formValue.newComponentsForm.isFormValid ? 'w-auto adjust-iqsSsc-add-room-btn' : 'w-auto adjust-iqsSsc-add-room-btn mb-2'}
                  newVersionUi={true}
                />
            </div>
          </div>
          {
            newComponentsUpdatedList.length >0 ?
            <ExpandableTable cols={getCols()} rows={newComponentsUpdatedList} dontShowExpandArrow={true} />
            : <div className='d-flex justify-content-center'><strong>{strings.noDataFoundText}</strong></div>
          }

        <div className='row'>
          <div className='col-sm-12'>
            <CreateStockActionButtons onSave={handleSaveKit} onCancel={cancelCreateKit} styleClass={'create-kit-button mt-3'}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCreateKitComponents;
