import React, { Component } from 'react';

import strings from 'resources/locales/Translate';

import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';
import AddMoreDeliveryTimes from 'library/common/commonComponents/AddMoreDeliveryTimes/AddMoreDeliveryTimes';
import { scrollToTop } from 'library/utilities/scrollActions';
import { deliveryTimesModel, deliveryTimesAlreadyAddedModel, provinceDeliveryTimesModel, deliveryTimesAlreadyAddedProvincesModel } from './DeliveryTimes.constants';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { WORKFLOW_CATEGORY_QUESTION_IDS } from '../Workflows/workflows.constants';
import { eq, isEqual } from 'lodash';

const sortByCountry = data =>
  data.sort((a, b) =>
    a?.countryIdFullValue?.name > b?.countryIdFullValue?.name
      ? 1
      : a?.countryIdFullValue?.name < b?.countryIdFullValue?.name
      ? -1
      : 0,
  );

const calculateForm = (data, countries) => {
  const countriesIds = [];
  const formsArrayToDisplay = [];
  data.forEach(item => {
    countriesIds.push(item.countryId);
    formsArrayToDisplay.push({
      ...item,
      countryIdFullValue: countries.find(country => country.id === item.countryId),
    });
  });
  return {
    countries: countries.filter(country => !countriesIds.includes(country.id)),
    formsArrayToDisplay: sortByCountry(formsArrayToDisplay),
  };
};

const calculateProvinceForm = (data, provinces, countries, stockCountry ) => {
  const provinceIds = [];
  const provincesArrayToDisplay = [];
  data.forEach(item => {
    provinceIds.push(item.stateId);
    provincesArrayToDisplay.push({
      ...item,
      stateIdFullValue: provinces.find(province => province.stateId === item.stateId),
      countryIdFullValue: countries.find(country => country.id === item.countryId),
    });
  });
  return {
    provinces: provinces.filter(province => !provinceIds.includes(province.stateId)),
    provincesArrayToDisplay: sortByCountry(provincesArrayToDisplay),
    newFormDataProvince: { 
      countryIdFullValue: countries.find(country => country.id === stockCountry),
      countryId:stockCountry, countryIdError:null
    }
  };
};

export default class DeliveryTimes extends Component {
  static getDerivedStateFromProps(props, state) {
    if (!state.countries.length && props.masterData.countries && props.stockDetails.country) {
      const countries = props.masterData.countries.filter(country => country.id !== props.stockDetails.country);
      return { countries };
    }
    if (state.countries && state.deliveryTimes && !state.formsArrayToDisplay) {
      return {
        ...calculateForm(state.deliveryTimes, props.masterData.countries),
      };
    }
    if (state.allProvinces && state.provinceDeliveryTimes && !state.provincesArrayToDisplay) {
      return {
        ...calculateProvinceForm(state.provinceDeliveryTimes, state.allProvinces, props.masterData.countries, props.stockDetails.country),
      };
    }
    return null;
  }

  state = {
    countries: [],
    deliveryTimes: null,
    loading: false,
    formsArrayToDisplay: null,
    idsToDelete: [],
    newFormData: {},
    showReturnDeliverAddress: false,
    provinceDeliveryTimes: null,
    provincesArrayToDisplay: null,
    provinces: null,
    allProvinces: null,
    newFormDataProvince:  {
      countryIdFullValue: this.props.masterData.countries.find(country => country.id === this.props.stockDetails.country),
      countryId:this.props.stockDetails.country, countryIdError:null, 
    }
  };

  componentDidMount() {
    this.fetchStockProvincesDeliveryTime();
    this.fetchProvincesOfCountry();
    this.fetchStockDeliveryTime();
    this.checkIfOtherReturnDeliverAddressEnabled();
    if (!this.props.masterData?.activeProvinceCountries.length){
      this.props.getAllActiveProvinceCountries();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.deliveryTimes &&
      ((this.props.isActive && prevProps.isActive) || (this.props.stockId && !prevProps.stockId))
    ) {
      this.fetchStockDeliveryTime();
    }
    if(!isEqual(prevProps.stockWorkflowDetails, this.props.stockWorkflowDetails)){
      this.checkIfOtherReturnDeliverAddressEnabled()
    }
    if(
      !isEqual(prevProps.stockAddressesList, this.props.stockAddressesList) || 
      !isEqual(this.state.formsArrayToDisplay, prevState.formsArrayToDisplay)
    ){
      this.setReturnDeliveryAddresses()
    }
    if (
      !this.state.provinceDeliveryTimes &&
      ((this.props.isActive && prevProps.isActive) || (this.props.stockId && !prevProps.stockId))
    ) {
      this.fetchStockProvincesDeliveryTime();
    }
  }

  setReturnDeliveryAddresses = () => {
    this.setState({newFormData: this.preSelectDefaultDeliveryAddress()})
    if(this.state.formsArrayToDisplay?.length && this.props.stockAddressesList?.length && this.state.countries.length){
      let formsArrayToDisplay = this.state.formsArrayToDisplay
      formsArrayToDisplay = formsArrayToDisplay.map((formData) => {
        let selectedAddress = this.getAddressOptions().find((address) => address.addressId === (formData.returnDeliveryAddress || formData.returnDeliveryAddressId))
        if(selectedAddress === null || selectedAddress === undefined){
          selectedAddress = this.getAddressOptions().find((address) => address.activeAddress)
        }
        formData = {
          ...formData,
          returnDeliveryAddress: formData.returnDeliveryAddress || formData.returnDeliveryAddressId,
          returnDeliveryAddressError: null,
          returnDeliveryAddressFullValue: selectedAddress
        }
        return formData
      })
      this.setState({formsArrayToDisplay})
    }
  }

  preSelectDefaultDeliveryAddress = () => {
    let selectedAddress = this.getAddressOptions().find((address) => address.activeAddress)
    return {
      returnDeliveryAddress: selectedAddress.addressId,
      returnDeliveryAddressFullValue: selectedAddress,
      returnDeliveryAddressError: null
    }
  }

  fetchStockDeliveryTime = () => {
    if (this.props.stockId && !this.state.loading) {
      this.setState({ loading: true }, async () => {
        const { success, data: deliveryTimes } = await this.props.fetchStockDeliveryTime(this.props.stockId);
        if (success) {
          this.setState({ deliveryTimes, loading: false });
        }
      });
    }
  };

  fetchStockProvincesDeliveryTime = () => {
    if (this.props.stockId && !this.state.loading) {
      this.setState({ loading: true }, async () => {
        const { success, data } = await this.props.fetchStockProvincesDeliveryTime(this.props.stockId);
        if (success) {
          this.setState({ provinceDeliveryTimes: data, loading: false });
        }
      });
    }
  };

  fetchProvincesOfCountry = () => {
    if (this.props.stockId && !this.state.loading && this.props.stockDetails.country) {
      this.setState({ loading: true }, async () => {
        const { success, data } = await this.props.getProvincesOfCountry(this.props.stockDetails.country);
        if (success) {
          this.setState({ provinces: data, loading: false, allProvinces: data});
        }
      });
    }
  };


  checkIfOtherReturnDeliverAddressEnabled = () => {
    const { stockWorkflowDetails } = this.props
    if(stockWorkflowDetails?.stockWorkflowDTOS && stockWorkflowDetails?.stockWorkflowDTOS.length){
      const {stockWorkflowDTOS } = stockWorkflowDetails;
      const generalWorkflows = stockWorkflowDTOS[0];
      const { stockWorkflowSettingDTO } = generalWorkflows;
      let enabled = stockWorkflowSettingDTO.find(category => category.categoryQuestionId === WORKFLOW_CATEGORY_QUESTION_IDS.enableDifferentDeliveryAddress && category.categoryAnswer === "yes")
      this.setState({showReturnDeliverAddress: enabled ? true: false})
      return enabled ? true: false 
    }
  }

  handleAdd = (formData, index, valueAlreadyAdded) => {
    const formsArrayToDisplay = [...this.state.formsArrayToDisplay];
    formsArrayToDisplay.push(formData);
    this.setState({ ...calculateForm(formsArrayToDisplay, this.props.masterData.countries) });
  };

  handleAddprovince = (formData, index, valueAlreadyAdded) => {
    const provincesArrayToDisplay = [...this.state.provincesArrayToDisplay];
    provincesArrayToDisplay.push({...formData,countryId:  this.props.stockDetails.country});
    this.setState({ ...calculateProvinceForm(provincesArrayToDisplay, this.state.allProvinces, this.props.masterData.countries, this.props.stockDetails.country) });
    this.setState({newFormDataProvince :{
      countryIdFullValue: this.props.masterData.countries.find(country => country.id === this.props.stockDetails.country),
      countryId:this.props.stockDetails.country, countryIdError:null, 
    }})
  };


  handleChange = (formData, index, valueAlreadyAdded) => {
    const formsArrayToDisplay = [...this.state.formsArrayToDisplay];
    if (formsArrayToDisplay[index]) {
      formsArrayToDisplay[index] = formData;
      this.setState({ formsArrayToDisplay });
    } else {
      this.setState({ newFormData: formData });
    }
  };

  handleChangeProvince = (formData, index, valueAlreadyAdded) => {
    const provincesArrayToDisplay = [...this.state.provincesArrayToDisplay];
    if (provincesArrayToDisplay[index]) {
      provincesArrayToDisplay[index] = formData;
      this.setState({ provincesArrayToDisplay });
    } else {
      this.setState({ newFormDataProvince: formData });
    }
  };

  handleRemove = (index, deliveryTimeId) => {
    const idsToDelete = [...this.state.idsToDelete];
    const formsArrayToDisplay = [...this.state.formsArrayToDisplay];
    formsArrayToDisplay.splice(index, 1);

    if (deliveryTimeId && deliveryTimeId > 0) {
      idsToDelete.push(deliveryTimeId);
    }
    this.setState({ ...calculateForm(formsArrayToDisplay, this.props.masterData.countries), idsToDelete });
  };

  handleRemoveProvince = (index, deliveryTimeId) => {
    const idsToDelete = [...this.state.idsToDelete];
    const provincesArrayToDisplay = [...this.state.provincesArrayToDisplay];
    provincesArrayToDisplay.splice(index, 1);

    if (deliveryTimeId && deliveryTimeId > 0) {
      idsToDelete.push(deliveryTimeId);
    }
    this.setState({ ...calculateProvinceForm(provincesArrayToDisplay, this.state.allProvinces, this.props.masterData.countries, this.props.stockDetails.country), idsToDelete });
  };

  generateDataForAPI = async () => {
    const { formsArrayToDisplay, idsToDelete, provincesArrayToDisplay  } = this.state;
    const arrayToSend = [];
    if (formsArrayToDisplay.length || idsToDelete.length || provincesArrayToDisplay.length) {
      let isFormValid = true;
      formsArrayToDisplay.forEach((formData, index) => {
        const form = this.validateRow({ formData }, index);
        if (form.isFormValid) {
          arrayToSend.push({
            deliveryTimeId: formData.deliveryTimeId || 0,
            countryId: formData.countryId,
            deliveryDays: formData.deliveryDays,
            returnDeliveryDays: formData.returnDeliveryDays,
            returnDeliveryAddressId: formData.returnDeliveryAddress,
            stateId: 0,
            isCountryDeliveryTime: true
          });
        }
        isFormValid = isFormValid && form.isFormValid;
      });

      provincesArrayToDisplay.forEach((formData, index) => {
        const form = this.validateRowForProvince({ formData }, index);
        if (form.isFormValid) {
          arrayToSend.push({
            deliveryTimeId: formData.deliveryTimeId || 0,
            countryId: this.props.stockDetails.country,
            deliveryDays: formData.deliveryDays,
            returnDeliveryDays: formData.returnDeliveryDays,
            returnDeliveryAddressId: formData.returnDeliveryAddress,
            stateId: formData.stateId,
            isCountryDeliveryTime: false
          });
        }
        isFormValid = isFormValid && form.isFormValid;
      });

      if (isFormValid) {
        const { success, data: deliveryTimes } = await this.props.createStockDeliveryTime(
          arrayToSend,
          idsToDelete,
          this.props.stockId,
        );
        if (success) {
          this.setState({
            ...calculateForm(deliveryTimes, this.props.masterData.countries),
            deliveryTimes,
            idsToDelete: [],
          });
        }
      } else {
        this.props.toggleActionMessage(true, 'error', 'formIsInvalid');
      }
    } else {
      this.props.toggleActionMessage(true, 'error', 'pleaseClickAddButtonMessage');
    }
    scrollToTop(500);
  };

  validateRow = (form, index) => {
    const formData = validateForm({ form, model: deliveryTimesAlreadyAddedModel });
    if (!formData.isFormValid) {
      this.handleChange(formData.formData, index, true);
    }
    return formData;
  };

  validateRowForProvince = (form, index) => {
    const formData = validateForm({ form, model: deliveryTimesAlreadyAddedProvincesModel });
    if (!formData.isFormValid) {
      this.handleChangeProvince(formData.formData, index, true);
    }
    return formData;
  };

  cancelStockCreation = () => {
    this.props.cancelStockCreation();
    this.props.history.replace('/stocks');
  };

  getAddressOptions = () => {
    let mappedAddesses = [];
    const {stockAddressesList} = this.props
    if(this.props?.stockAddressesList && this.props?.stockAddressesList.length){
      mappedAddesses = stockAddressesList.map((address) => {
        return {...address, id: address.addressId, name: address.addressType}
      })
    }
    return mappedAddesses;
  }

  deliveryTimesModel = () => {
    let model = [...deliveryTimesModel];
    model[0].options = this.state.countries;
    if(!this.state.showReturnDeliverAddress){
      model.splice(3, 1)
      model = model.map(form => ({...form, styleClass: 'col-sm-4' })) 
    }else{
      model[3].options = this.getAddressOptions()
    }
    return model;
  };

  provinceDeliveryTimesModel = () => {
    let model = [...provinceDeliveryTimesModel];
    model[0].options = this.state.provinces;
    if(!this.state.showReturnDeliverAddress){
      model.splice(3, 1)
      model = model.map(form => ({...form, styleClass: 'col-sm-4' })) 
    }
    return model;
  };

  getAllreadyAddedModel = () => {
    let model = [...deliveryTimesAlreadyAddedModel]
    if(this.state.showReturnDeliverAddress){
      model[3].options = this.getAddressOptions();
    }else{
      model.splice(3, 1)
      model = model.map(form => ({...form, styleClass: 'col-sm-4' })) 
    }
    return [...model]
  }

  getAllreadyAddedProvincesModel = () => {
    let model = [...deliveryTimesAlreadyAddedProvincesModel]
    if(!this.state.showReturnDeliverAddress){
      model.splice(3, 1)
      model = model.map(form => ({...form, styleClass: 'col-sm-4' })) 
    }
    return [...model]
  }

  render() {
    const { canEditStock, masterData } = this.props;
    const { formsArrayToDisplay, newFormData, provincesArrayToDisplay, provinces, newFormDataProvince } = this.state;
    return (
      <div className='delivery-time-container'>
        <div className='container-fluid form-container'>
          <div className='row'>
            <div className='col-sm-9'>
              <h4 className='form-title'>{strings.specialDeliveryTimes}</h4>
              <div className='forms'>
                {formsArrayToDisplay &&
                  !!formsArrayToDisplay.length &&
                    formsArrayToDisplay.map((form, index) => (
                    <AddMoreDeliveryTimes
                      key={`${form.countryId}${index}`}
                      index={index}
                      value={form}
                      countries={masterData.countries}
                      model={this.getAllreadyAddedModel()}
                      hideDeleteIcon={!canEditStock}
                      valueAlreadyAdded
                      onRemove={this.handleRemove}
                      onChange={this.handleChange}
                      fromProvinces={false}
                      stockCountryId={this.props.stockDetails.country}
                    />
                  ))}
                <div className='form'>
                  {canEditStock && (
                    <AddMoreDeliveryTimes
                      model={this.deliveryTimesModel()}
                      valueAlreadyAdded={false}
                      onAdd={this.handleAdd}
                      onChange={this.handleChange}
                      value={newFormData}
                      fromProvinces={false}
                    />
                  )}
                </div>
              </div>
            </div>
            {this.props.masterData?.activeProvinceCountries.includes(this.props.stockDetails.country) &&  <div className='col-sm-9'>
              <h4 className='form-title'>{strings.specialProvinceDeliveryTimes}</h4>
              <div className='forms'>
                  {provincesArrayToDisplay &&
                    !!provincesArrayToDisplay.length &&
                      provincesArrayToDisplay.map((form, index) => (
                      <AddMoreDeliveryTimes
                        key={`${form.countryId}${index}`}
                        index={index}
                        value={form}
                        countries={masterData.countries}
                        model={this.getAllreadyAddedProvincesModel()}
                        hideDeleteIcon={!canEditStock}
                        valueAlreadyAdded
                        onRemove={this.handleRemoveProvince}
                        onChange={this.handleChangeProvince}
                        provinces={this.state.provinces}
                        fromProvinces={true}
                        stockCountryId={this.props.stockDetails.country}
                      />
                  ))}

                  <div className='form'>
                    {canEditStock && (
                      <AddMoreDeliveryTimes
                        model={this.provinceDeliveryTimesModel()}
                        valueAlreadyAdded={false}
                        onAdd={this.handleAddprovince}
                        onChange={this.handleChangeProvince}
                        value={newFormDataProvince}
                        fromProvinces={true}
                        countries={masterData.countries}
                      />
                    )}
                  </div>
                </div>
              </div>}
          </div>
          {canEditStock && (
            <CreateStockActionButtons onSave={this.generateDataForAPI} onCancel={this.cancelStockCreation} styleClass={'save-next-pev-button'}/>
          )}
        </div>
      </div>
    );
  }
}
